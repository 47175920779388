import { FaBriefcase, FaMoneyCheck, FaWallet } from "react-icons/fa";
import { BsWhatsapp } from "react-icons/bs";
import { FiCopy, FiLink, FiTwitter } from "react-icons/fi";
import { RiFacebookCircleLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { AiFillDatabase } from "react-icons/ai";
import React, { useEffect, useState } from "react";
import "./style.css";
import Table from "../../components/table/Table";
import { toast } from "react-toastify";
import {
  getAllBonuses,
  getAllPackages,
  getAnnoucment,
  getLiveRate,
  getRewardById,
  getUSDTCredits,
  getWalletById,
} from "../../../services/Member";
import Loader from "../../components/loader/Loader";
import CustomTable from "../../components/custom-table/CustomTable";

const fields = [
  { key: "Sl. No", label: "S.no" },
  { key: "Date", label: "Date" },
  { key: "Purchased Amount", label: "Purchased Amount (USDT)" },
  { key: "Invested coin", label: "Invested coin (ADVB)" },
  { key: "Invested By", label: "Invested By" },
  { key: "ROI", label: "ROI (ADVB)" },
  { key: "Status", label: "Status" },
  // Add more fields as needed
];

const columns = [
  {
    Header: "S.No",
    accessor: "sn",
  },
  {
    Header: "Investment Date",
    accessor: "date",
  },
  {
    Header: "Amount",
    accessor: "amount",
  },
  {
    Header: "Token Rate",
    accessor: "tokenRate",
  },
  {
    Header: "Tokens (ADVB)",
    accessor: "tokens",
  },
  {
    Header: "Profit",
    accessor: "profit",
  },
  {
    Header: "Status",
    accessor: "status",
  },
];

function formatDate(dateString) {
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  return new Date(dateString).toLocaleDateString("en-GB", options);
}

function Dashboard() {
  const initialLoaderState = {
    walletDetailsLoading: true,
    activePackages: true,
  };

  const initialStatisticsData = {
    teamBusiness: {
      title: "Team Business",
      amount: "Loading..",
      to: "/member/team-business",
    },
    liveRate: {
      title: "Live rate",
      amount: "Loading..",
      to: "",
    },
    depositWallet: {
      title: "Deposit Wallet",
      amount: "Loading..",
      to: "/member/deposit",
    },
    totalInvestment: {
      title: "Total Investment",
      amount: "Loading..",
      to: "/member/investment-history",
    },
    retopup: {
      title: "Retopup",
      amount: "Loading..",
      to: "/member/retopup",
    },
    directBonus: {
      title: "Direct Bonus",
      amount: "Loading..",
      to: "/member/working-income?incomeType=Direct%20Bonus",
    },
    mentorshipBonus: {
      title: "Mentorship Bonus",
      amount: "Loading..",
      to: "/member/working-income?incomeType=Mentorship%20Bonus",
    },
    royaltyBonus: {
      title: "Royalty Bonus",
      amount: "Loading..",
      to: "/member/royalty-bonus",
    },
    dividendBonus: {
      title: "Dividend Bonus",
      amount: "Loading..",
      to: "/member/working-income?incomeType=Dividend%20Bonus",
    },
    rewardBonus: {
      title: "Reward Bonus",
      amount: "Loading..",
      to: "/member/working-income?incomeType=Reward%20Bonus",
    },
    withdrawal: {
      title: "Withdrawal",
      amount: "Loading..",
      to: "/member/working-income?incomeType=Withdrawal",
    },
    utility: {
      title: "Utility Wallet",
      amount: 0,
      to: "member/working-income?incomeType=Mentorship%20Bonus",
    },
  };

  const [investmentHistory, setInvestmentHistory] = useState([]);
  const [announcement, setAnnouncement] = useState([]);
  const [statisticsData, setStatisticsData] = useState(initialStatisticsData);
  const [dashboardData, setDashboardData] = useState({
    totalInvestment: 0.0,
    ROIStaked: 0.0,
    ADVBWallet: 0.0,
    USDTWallet: 0.0,
  });

  const [isLoading, setIsLoading] = useState(initialLoaderState); // Initial loader state object

  const memberData = JSON.parse(localStorage.getItem("memberData"));

  const copyReferralLink = (item) => {
    const referralLink = `${window.location.origin}/dashboard/register?sponsorId=${memberData.memberId}`;

    if (item === "redirect") {
      const newTab = window.open(referralLink, "_blank");
      if (newTab) {
        newTab.focus();
      } else {
        toast.error("Failed to open new tab.");
      }
    } else {
      const textField = document.createElement("textarea");
      textField.innerText = referralLink;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand("copy");
      textField.remove();

      // You can show a success message here
      toast.success("Referral linked copied");
    }
  };

  const fetchStaticsdata = async () => {
    const rewards = await getRewardById(memberData.memberId);
    const allbonuses = await getAllBonuses(memberData.memberId);

    setStatisticsData((prev) => ({
      ...prev,
      directBonus: {
        ...prev.directBonus,
        amount: allbonuses.directBonus || 0,
      },
      mentorshipBonus: {
        ...prev.mentorshipBonus,
        amount: allbonuses.mentorshipBonus || 0,
      },
      royaltyBonus: {
        ...prev.royaltyBonus,
        amount: allbonuses.royaltyBonus || 0,
      },
      dividendBonus: {
        ...prev.dividendBonus,
        amount: allbonuses.dividendBonus || 0,
      },
      rewardBonus: {
        ...prev.rewardBonus,
        amount: allbonuses.rewardBonus || 0,
      },
      withdrawal: {
        ...prev.withdrawal,
        amount: allbonuses.withdrawal || 0,
      },
      teamBusiness: {
        ...prev.teamBusiness,
        amount: rewards.result.reward.teamBusiness || 0,
      },
    }));
  };

  const fetchWalletDetails = async () => {
    try {
      const walletDetails = await getWalletById(memberData.memberId);
      // const usdtCredits = await getUSDTCredits(memberData.memberId);
      const liveRate = await getLiveRate();

      setDashboardData({
        totalInvestment: walletDetails.result.totalInvestment || 0,
        ROIStaked: walletDetails.result.wallet.ROIStaked || 0,
        ADVBWallet: walletDetails.result.wallet.ADVBWallet.amount || 0,
        USDTWallet: walletDetails.result.wallet.USDTWallet.amount || 0,
      });
      const announcement = await getAnnoucment();
      setAnnouncement(announcement?.result);

      setStatisticsData((prev) => ({
        ...prev,
        depositWallet: {
          ...prev.depositWallet,
          amount: walletDetails.result.wallet.ADVBDeposit || 0,
        },
        liveRate: {
          ...prev.liveRate,
          amount: liveRate || 0,
        },
        totalInvestment: {
          ...prev.totalInvestment,
          amount: walletDetails.result.totalInvestment || 0,
        },
        retopup: {
          ...prev.retopup,
          amount: walletDetails.result.wallet.retopupWallet || 0,
        },
      }));

      setIsLoading((prevLoadingState) => ({
        ...prevLoadingState,
        walletDetailsLoading: false,
      }));
    } catch (error) {
      console.error("Error fetching user details:", error);
      setIsLoading((prevLoadingState) => ({
        ...prevLoadingState,
        walletDetailsLoading: false,
      }));
    }
  };

  const fetchInvestmentHistory = async () => {
    try {
      setIsLoading((prevLoadingState) => ({
        ...prevLoadingState,
        fetchWalletDetails: true,
      }));
      const investmentData = await getAllPackages(memberData.memberId);
      const formattedData = investmentData.result.map((entry, index) => ({
        sn: index + 1,
        date: formatDate(entry.timeOfEvent),
        amount: entry.purchasedAmount ? entry.purchasedAmount.toFixed(2) : 0,
        tokenRate: entry.liveToken,
        tokens: entry.investedAmount ? entry.investedAmount.toFixed(2) : 0,
        profit: entry.ROI ? entry.ROI.toFixed(2) : 0,
        status: (
          <h4 style={{ color: entry.status === "Active" ? "green" : "red" }}>
            {entry.status}
          </h4>
        ), // Map other fields as needed
      }));
      setInvestmentHistory(formattedData);
      setIsLoading((prevLoadingState) => ({
        ...prevLoadingState,
        activePackages: false,
      }));
    } catch (error) {
      console.error("Error fetching investment history:", error);
      setIsLoading((prevLoadingState) => ({
        ...prevLoadingState,
        activePackages: false,
      }));
    }
  };

  useEffect(() => {
    fetchWalletDetails();
    fetchInvestmentHistory();
    fetchStaticsdata();
  }, []);

  return (
    <>
      {isLoading.walletDetailsLoading || isLoading.activePackages ? (
        <Loader />
      ) : (
        <div className="dashboard">
          <h1 className="main-heading">Dashboard</h1>
          <div className="dashboard-zero">
            <div className="dz-item">
              <FaMoneyCheck />
              <h2>Total Investment</h2>
              <h1>
                ${" "}
                {dashboardData.totalInvestment
                  ? dashboardData.totalInvestment.toFixed(2)
                  : (0.0).toFixed(2)}
              </h1>
            </div>
            <div className="dz-item">
              <FaBriefcase />
              <h2>ROI Current balance</h2>
              <h1>
                {dashboardData.ROIStaked
                  ? dashboardData.ROIStaked.toFixed(2)
                  : (0.0).toFixed(2)}{" "}
                ADVB
              </h1>
            </div>
            <div className="dz-item">
              <FaWallet />
              <h2>ADVB Wallet</h2>
              <h1>
                {dashboardData.ADVBWallet
                  ? dashboardData.ADVBWallet.toFixed(2)
                  : (0.0).toFixed(2)}{" "}
                ADVB
              </h1>
              <h2>
                ${" "}
                {dashboardData.ADVBWallet
                  ? (
                      dashboardData.ADVBWallet * statisticsData.liveRate.amount
                    ).toFixed(2)
                  : (0.0).toFixed(2)}
              </h2>
            </div>
            <div className="dz-item">
              <FaWallet />
              <h2>USDT Wallet</h2>
              <h1>
                ${" "}
                {dashboardData.USDTWallet
                  ? dashboardData.USDTWallet.toFixed(2)
                  : (0.0).toFixed(2)}
              </h1>
              <h2>
                {" "}
                {dashboardData.USDTWallet
                  ? (dashboardData.USDTWallet / statisticsData.liveRate.amount).toFixed(2)
                  : (0.0).toFixed(2)}{" "}
                ADVB
              </h2>
            </div>
          </div>

          <div className="dashboard-first">
            <div className="df-top">
              <h2 className="top-head">Announcement</h2>
            </div>
            <div className="df-main">
              <marquee behavior="scroll" direction="left">
                <div dangerouslySetInnerHTML={{ __html: announcement }} />
              </marquee>
            </div>
          </div>

          <div className="dashboard-second">
            <div className="ds-left">
              <img
                src="https://res.cloudinary.com/dev6cpp4u/image/upload/v1696426462/refer-earn-2_zdlybk.png"
                alt="ds-left"
              />
              <div className="dsl-down">
                <div
                  className="dsl-down-1"
                  onClick={() => copyReferralLink("redirect")}
                >
                  Referral Link <FiLink />
                </div>
                <div className="dsl-down-2" onClick={copyReferralLink}>
                  Invite friends <FiCopy />
                  <div className="dsld-icons">
                    <BsWhatsapp />
                    <RiFacebookCircleLine />
                    <FiTwitter />
                  </div>
                </div>
              </div>
            </div>

            <div className="ds-right">
              <div className="dsr-top">
                <h2 className="top-head">Statistics</h2>
              </div>
              <div className="dsr-body">
                {Object.values(statisticsData).map((item, index) => (
                  <div className="dsr-item" key={index}>
                    <Link className="dsri-left" to={item.to}>
                      <AiFillDatabase />
                      <h2>{item.title}</h2>
                    </Link>
                    <span>
                      {item.amount === "Loading.."
                        ? "Loading.."
                        : "$" + item.amount.toFixed(2)}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* table  */}

          <CustomTable
            columns={columns}
            data={investmentHistory}
            heading="My Packages"
          />
        </div>
      )}
    </>
  );
}

export default Dashboard;
