import React, { useEffect, useState } from "react";
import Loader from "../../components/loader/Loader";
import Table from "../../components/table/Table";
import { getAllReferrals, getTeamBusiness } from "../../../services/Member";
import CustomTable from "../../components/custom-table/CustomTable";

function TeamBusiness() {
  const columns = [
    {
      Header: "S.no",
      accessor: "sn",
    },
    {
      Header: "Member Id",
      accessor: "memberId",
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Registration Date",
      accessor: "registrationDate",
    },
    {
      Header: "Invested Amount",
      accessor: "investmentAmount",
    },
    {
      Header: "Team Business",
      accessor: "teamBusiness",
    },
    {
      Header: "Level",
      accessor: "level",
    },
    {
      Header: "Status",
      accessor: "status",
    },
  ];

  const [data, setData] = useState([]);
  const [totalTeamBusiness, setTotalTeamBusiness] = useState(0);
  const [isLoading, setIsLoading] = useState({
    referralsLoading: true,
  }); // Initial loader state object
  const memberData = JSON.parse(localStorage.getItem("memberData"));

  const fetchReferrals = async () => {
    try {
      const referralResponse = await getAllReferrals(memberData.memberId);
      const teamBusiness = await getTeamBusiness(memberData.memberId);
      setTotalTeamBusiness(teamBusiness.teamBusiness);

      const updatedReferrals = referralResponse.map((referral, i) => {

        const formattedDate = new Date(
          referral.RegistrationDate
        ).toLocaleDateString();

        return {
          ...referral,
          status: referral.InvestedAmount === 0 ? "Inactive" : "Active",
          "sn": i + 1,
          "memberId": referral.memberId || "none",
          "name": referral.Name || "none",
          "level": referral.Level,
          "registrationDate": formattedDate || "none", // Formatted date
          "investmentAmount": referral.InvestedAmount || 0,
          "teamBusiness": referral.teamBusiness ? referral.teamBusiness : 0,
        };
      });

      console.log(updatedReferrals)

      setData(updatedReferrals);
      setIsLoading((prevLoadingState) => ({
        ...prevLoadingState,
        referralsLoading: false,
      }));
    } catch (error) {
      console.error("Error fetching referrals:", error);
      setIsLoading((prevLoadingState) => ({
        ...prevLoadingState,
        referralsLoading: false,
      }));
    }
  };

  useEffect(() => {
    fetchReferrals();
  }, []);

  return (
    <>
      {isLoading.referralsLoading ? (
        <Loader />
      ) : (
        <div className="dashboard">
          <h1 className="main-heading">Team Business</h1>
          <div className="deposit-first">
            <div className="defi-top">
              <div className="defito-left">
                <h2>Total team business</h2>
              </div>
              <div className="defito-left">
                <h1>$ {totalTeamBusiness}</h1> {/* Display the total here */}
              </div>
            </div>
            <div className="defi-second"></div>
          </div>

          <br />
          <br />
          {/* table  */}

          <CustomTable data={data} columns={columns} heading="My Recent Referrals" />
        </div>
      )}
    </>
  );
}

export default TeamBusiness;
