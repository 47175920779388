import React, { useState, useEffect } from "react";
import "./style.css";
import Table from "../../components/table/Table";
import Loader from "../../components/loader/Loader";
import { getActivePackages, getAllPackages } from "../../../services/Member"; // Replace with your service method
import CustomTable from "../../components/custom-table/CustomTable";

function formatDate(dateString) {
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  return new Date(dateString).toLocaleDateString("en-GB", options);
}

function InvestmentHistory() {
  const [isLoading, setIsLoading] = useState(true);
  const [investmentHistory, setInvestmentHistory] = useState([]);
  const memberData = JSON.parse(localStorage.getItem("memberData"));

  const column = [
    {
      Header: "S.No",
      accessor: "sn",
    },
    {
      Header: "Date",
      accessor: "data",
    },
    {
      Header: "Tokens (ADVB)",
      accessor: "tokens",
    },
    {
      Header: "Purchased Amount",
      accessor: "purchasedAmount",
    },
    {
      Header: "Invested By",
      accessor: "investedBy",
    },
    {
      Header: "Profit (ADVB)",
      accessor: "profit",
    },
    {
      Header: "Status",
      accessor: "status",
    },
  ];

  // Fetch investment history
  const fetchInvestmentHistory = async () => {
    try {
      const investmentData = await getActivePackages(memberData.memberId); // Fetch investment data using your service method
      const formattedData = investmentData.result.map((entry, index) => ({
        "sn": index + 1,
        "data": formatDate(entry.timeOfEvent),
        "tokens": entry.investedAmount ? entry.investedAmount.toFixed(2) : 0,
        "purchasedAmount": entry.purchasedAmount,
        "investedBy": entry.investedBy, // Assuming you have the user who invested
        "profit": entry.ROI ? entry.ROI.toFixed(2) : 0 ,
        "status": (
          <h4 style={{ color: entry.status === "Active" ? "green" : "red" }}>
            {entry.status}
          </h4>
        ),         // Map other fields as needed
      }));
      setInvestmentHistory(formattedData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching investment history:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchInvestmentHistory();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="investment-history">
          <h1 className="main-heading">Investment History</h1>
          <br />
          <br />

          <CustomTable columns={column} data={investmentHistory} heading="Investment History"/>
        </div>
      )}
    </>
  );
}

export default InvestmentHistory;
