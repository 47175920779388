import React, { useEffect, useState } from "react";
import {
  getLiveRate,
  getLiveRateAPI,
  getProfileById,
  getWalletById,
  investAdvb,
} from "../../../services/Member";
import { toast } from "react-toastify";
import Loader from "../../components/loader/Loader";
import { Link, useNavigate } from "react-router-dom";
import Loader2 from "../../components/loader/Loader2";

function BuyPackage() {
  const [livePrice, setLivePrice] = useState(0);
  const [inputMemberId, setInputMemberId] = useState("");
  const [memberName, setMemberName] = useState("");
  const [forIn, setForIn] = useState("self");
  const [selectedPackage, setSelectedPackage] = useState("100");
  const [currentBalance, setCurrentBalance] = useState(0);
  const [isLoading, setIsLoading] = useState({
    fetchWalletDetails: true,
    fetchLiveRate: true,
  });
  const [isPending, setIsPending] = useState(false);
  const navigate = useNavigate();

  const memberData = JSON.parse(localStorage.getItem("memberData"));

  // Fetch live rate when the component mounts
  const fetchLiveRate = async () => {
    setIsLoading((prevLoadingState) => ({
      ...prevLoadingState,
      fetchLiveRate: true,
    }));
    try {
      const rate = await getLiveRate();
      setLivePrice(parseFloat(rate));
      setIsLoading((prevLoadingState) => ({
        ...prevLoadingState,
        fetchLiveRate: false,
      }));
    } catch (error) {
      console.error("Error fetching live rate:", error);
      setIsLoading((prevLoadingState) => ({
        ...prevLoadingState,
        fetchLiveRate: false,
      }));
    }
  };

  const handlePackageChange = (e) => {
    const packageValue = e.target.value;
    setSelectedPackage(packageValue);
  };

  const handleForInChange = (e) => {
    const forIn = e.target.value;
    setForIn(forIn);
  };

  const handleMemberIdChange = async (memberId) => {
    try {
      const response = await getProfileById(memberId);
      if (response.result) {
        setMemberName(response.result.profile.fullname);
      } else {
        setMemberName("");
      }
    } catch (error) {
      setMemberName("");
    }
  };

  const handleUserIdInputChange = (e) => {
    const memberId = e.target.value;
    setInputMemberId(memberId);
  };

  useEffect(() => {
    if (inputMemberId) {
      handleMemberIdChange(inputMemberId);
    } else {
      setMemberName("");
    }
  }, [inputMemberId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsPending(true);

    // Check if userId is empty
    if (!inputMemberId) {
      toast.warn("All fields require");
      setIsPending(false);
      return;
    }
    if (!memberName) {
      toast.warn("Enter Valid user Id");
      setIsPending(false);
      return;
    }
    if (livePrice === 0) {
      toast.warn("Cannot fetch live price!");
      setIsPending(false);
      return;
    }

    // Calculate the amount based on the selected package and live price
    const packageAmount = parseFloat(selectedPackage);
    const amount = packageAmount;

    if (currentBalance < packageAmount) {
      toast.warn("Not enough funds!");
      setIsPending(false);
      return;
    }

    try {
      // Assuming investAdvb is an async function
      const response = await investAdvb(
        memberData.memberId,
        inputMemberId,
        amount,
        amount / livePrice,
        "Deposit",
        livePrice,
      );
      // console.log(response);


      if (response.message === "Insufficient balance in the wallet") {
        toast.error("Insufficient balance in the wallet");
      } else if (response.message === "Investment successful") {
        toast.success("Investment successful!");
        navigate("/member/dashboard");
      } else if (response.message === "Member Already Activated!"){
        toast.warn("Id already activated! please select min 100$ package!");
      } else {
        toast.error("User not found");
      }
      setIsPending(false);
    } catch (error) {
      // Show error toast
      toast.error("Investment failed. Please try again later.");
      setIsPending(false);
    }

    // Reset the form fields
    e.target.reset();
    fetchWalletDetails();
  };

  useEffect(() => {
    if (forIn === "self") {
      setInputMemberId(memberData.memberId);
    } else {
      setInputMemberId("");
    }
  }, [forIn]);

  const fetchWalletDetails = async () => {
    try {
      const walletDetails = await getWalletById(memberData.memberId);
      setCurrentBalance(walletDetails.result.wallet.ADVBDeposit);
      setIsLoading((prevLoadingState) => ({
        ...prevLoadingState,
        fetchWalletDetails: false,
      }));
    } catch (error) {
      console.error("Error fetching user details:", error);
      setIsLoading((prevLoadingState) => ({
        ...prevLoadingState,
        fetchWalletDetails: false,
      }));
    }
  };

  useEffect(() => {
    fetchWalletDetails();
    fetchLiveRate(); // Call the function
  }, []);

  return (
    <>
      {isLoading.fetchWalletDetails || isLoading.fetchLiveRate ? (
        <Loader />
      ) : (
        <>
          {isPending && <Loader2 />}{" "}
          <div className="deposit">
            <h1 className="main-heading">Adverbcrypto Package</h1>

            <div className="adpa-first">
              <div className="adpafi-top">
                <h2>Buy Package</h2>
                <div>
                  <Link to="/member/investment-history">Invest History</Link>
                </div>
              </div>
              <div className="adpafi-body">
                <form onSubmit={handleSubmit}>
                  <div className="input-group">
                    <label>Deposit Fund</label>
                    <input
                      type="text"
                      disabled
                      value={`$ ${
                        currentBalance ? currentBalance.toFixed(2) : 0
                      }`}
                    />
                  </div>
                  <div className="input-group">
                    <label>Buy package for</label>
                    <select
                      name="for"
                      value={forIn}
                      onChange={handleForInChange}
                    >
                      <option value="self">For self</option>
                      <option value="others">For others</option>
                    </select>
                  </div>
                  <div className="input-group">
                    <label>User Id</label>
                    <input
                      type="text"
                      placeholder="Enter User Id"
                      disabled={forIn === "self"}
                      name="inputMemberId"
                      value={inputMemberId}
                      onChange={handleUserIdInputChange}
                    />
                  </div>

                  {memberName && (
                    <div className="input-group">
                      <label>User Name</label>
                      <input
                        type="text"
                        placeholder="User name"
                        disabled
                        name="inputMemberId"
                        value={memberName}
                      />
                    </div>
                  )}

                  <div className="input-group">
                    <label>Select Package</label>
                    <select
                      name="package"
                      onChange={handlePackageChange}
                      value={selectedPackage}
                    >
                      <option value="0">Package 0USD</option>
                      <option value="100">Package 100USD</option>
                      <option value="200">Package 200USD</option>
                      <option value="500">Package 500USD</option>
                      <option value="1000">Package 1000USD</option>
                      <option value="1100">Package 1100USD</option>
                      <option value="1200">Package 1200USD</option>
                      <option value="1300">Package 1300USD</option>
                      <option value="1400">Package 1400USD</option>
                      <option value="1500">Package 1500USD</option>
                      <option value="1600">Package 1600USD</option>
                      <option value="1700">Package 1700USD</option>
                      <option value="1800">Package 1800USD</option>
                      <option value="1900">Package 1900USD</option>
                      <option value="2000">Package 2000USD</option>
                      <option value="3000">Package 3000USD</option>
                      <option value="5000">Package 5000USD</option>
                      <option value="10000">Package 10000USD</option>
                      <option value="15000">Package 15000USD</option>
                      <option value="20000">Package 20000USD</option>
                      <option value="25000">Package 25000USD</option>
                    </select>
                  </div>

                  <div className="input-group">
                    <label>Package in ADVB Coin rate</label>
                    <input
                      type="text"
                      disabled
                      value={`${
                        selectedPackage / livePrice
                          ? (selectedPackage / livePrice).toFixed(2)
                          : "0"
                      } coin`}
                    />
                  </div>

                  <div className="input-group">
                    <label>Live Rate</label>
                    <input type="text" disabled value={`$ ${livePrice}`} />
                  </div>
                  <div className="button-group">
                    <button type="submit">Buy</button>
                    <button type="button" onClick={fetchLiveRate}>
                      Refresh
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default BuyPackage;
