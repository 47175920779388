// MenuItems.js
const Menuitems = [
  {
    id: 1,
    label: "Dashboard",
    link: "/admin/dashboard",
  },
  {
    id: 2,
    label: "Master",
    submenus: [
      {
        id: 1,
        label: "Settings",
        link: "/admin/settings",
        img: "https://res.cloudinary.com/dev6cpp4u/image/upload/v1690181271/invest_yss3bh.png",
        desc: "Hello this is description for the sub menu."
      }
    ],
  },
  {
    id: 3,
    label: "Customer",
    submenus: [
      {
        id: 1,
        label: "All Members",
        link: "/admin/all-members",
        img: "https://res.cloudinary.com/dev6cpp4u/image/upload/v1690181271/invest_yss3bh.png",
        desc: "Hello this is description for the sub menu."
      },
      {
        id: 2,
        label: "Update Profile",
        link: "/admin/profile-update",
        img: "https://res.cloudinary.com/dev6cpp4u/image/upload/v1690181271/invest_yss3bh.png",
        desc: "Hello this is description for the sub menu."
      },
     
    ],
  },
  {
    id: 4,
    label: "Payout",
    submenus: [
      {
        id: 1,
        label: "USDT Payout",
        link: "/admin/usdt-payout",
        img: "https://res.cloudinary.com/dev6cpp4u/image/upload/v1690181271/invest_yss3bh.png",
        desc: "Hello this is description for the sub menu."
      },
      {
        id: 2,
        label: "USDT Payout Approved",
        link: "/admin/usdt-payout-approved",
        img: "https://res.cloudinary.com/dev6cpp4u/image/upload/v1690181271/invest_yss3bh.png",
        desc: "Hello this is description for the sub menu."
      },
      {
        id: 4,
        label: "USDT Payout Failed",
        link: "/admin/usdt-payout-failed",
        img: "https://res.cloudinary.com/dev6cpp4u/image/upload/v1690181271/invest_yss3bh.png",
        desc: "Hello this is description for the sub menu."
      },
      {
        id: 5,
        label: "ADVB Payout",
        link: "/admin/advb-payout",
        img: "https://res.cloudinary.com/dev6cpp4u/image/upload/v1690181271/invest_yss3bh.png",
        desc: "Hello this is description for the sub menu."
      },
      {
        id: 6,
        label: "ADVB Payout Approved",
        link: "/admin/advb-payout-approved",
        img: "https://res.cloudinary.com/dev6cpp4u/image/upload/v1690181271/invest_yss3bh.png",
        desc: "Hello this is description for the sub menu."
      },
      {
        id: 8,
        label: "ADVB Payout Failed",
        link: "/admin/advb-payout-failed",
        img: "https://res.cloudinary.com/dev6cpp4u/image/upload/v1690181271/invest_yss3bh.png",
        desc: "Hello this is description for the sub menu."
      },
    ],
  },
  
  {
    id: 4,
    label: "Log out",
    link: "/logout",
  },

];

export default Menuitems;
