import { React, useEffect, useState } from "react";
import logo from "../../assets/images/main-logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios"; // Import axios

function Register() {
  const [formData, setFormData] = useState({
    sponsorId: "",
    fullName: "",
    email: "",
    country: "",
    mobile: "",
    password: "",
  });
  const [isPending, setIsPending] = useState(false); // Add isPending state
  const [isRegister, setIsRegister] = useState(false)
  const [visibile, setVisible] = useState(false);
  const [registerData, setRegisterData] = useState()
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    const sponsorId = queryParams.get("sponsorId");
    if (sponsorId) {
      setFormData((prevData) => ({
        ...prevData,
        sponsorId: sponsorId,
      }));
    }
  }, [location.search]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setIsPending(true);
  
    for (const value of Object.values(formData)) {
      if (value === "") {
        toast.warn("All fields are required!");
        setIsPending(false);
        return;
      }
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}auth/register`,
        {
          sponsorId: formData.sponsorId,
          fullName: formData.fullName,
          email: formData.email,
          country: formData.country,
          mobile: formData.mobile,
          password: formData.password,
        }
      );
  
      if (response.status === 201) {
        setIsRegister(true)
        setRegisterData(response.data.result.savedMember.memberId)
      }
      toast.success("Registration successfull!")
      setIsPending(false);
    } catch (error) {
      console.error("Registration Error:", error);
  
      if (error.response && error.response.status === 400) {
        if (error.response.data.message === "Invalid Sponsor Id") {
          toast.error("Invalid Sponsor Id.");
        } else {
          toast.error("An error occurred while registering.");
        }
      } else {
        toast.error("An error occurred while registering.");
      }
  
      setIsPending(false);
    }
  };
  
  

  return (
    <>
      <div className="login">
        <div className="login-first">
          <img className="login-logo" src={logo} alt="Logo" />
        </div>
        
        {
          !isRegister && <div className="login-main">
          <div className="loma-first">
            <h2>Register</h2>
            <Link to="/login">Already have an account?</Link>
          </div>
          <form>
            <div className="loma-second">
              <div className="input-group-2">
                <label>Sponsor Id</label>
                <input
                  type="text"
                  name="sponsorId"
                  placeholder="AV12345"
                  value={formData.sponsorId}
                  onChange={handleInputChange}
                  disabled={queryParams.get("sponsorId") !== null}
                  required
                />
              </div>
              <div className="input-group-2">
                <label>Full Name</label>
                <input
                  type="text"
                  name="fullName"
                  placeholder="Enter Full Name"
                  value={formData.fullName}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="input-group-2">
                <label>Email Address</label>
                <input
                  type="email"
                  name="email"
                  placeholder="Enter Email Address"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="input-group-2">
                <label>Country</label>
                <input
                  type="text"
                  name="country"
                  placeholder="Enter your country"
                  value={formData.country}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="input-group-2">
                <label>Mobile</label>
                <input
                  type="text"
                  name="mobile"
                  placeholder="+91 1234567890"
                  value={formData.mobile}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="input-group-2 eye-parent">
                <label>Password</label>
                <input
                  type={visibile ? "text" : "password"}
                  name="password"
                  placeholder="Enter Password"
                  value={formData.password}
                  onChange={handleInputChange}
                  required
                />
                <div className="eye" onClick={()=>setVisible((prev)=>!prev)}>
                {
                  visibile ? <AiFillEye /> : <AiFillEyeInvisible />
                }
              </div>
              </div>
            </div>
          
            <div className="loma-fourth">
              <button onClick={handleRegister} disabled={isPending}>
                {isPending ? "Loading..." : "Register"}
              </button>
            </div>
          </form>
        </div>
        }


        {
          isRegister && <div className="login-main">
          <div className="loma-first">
            <h2>Welcome To Adverb</h2>
          </div>
            <p>Registration successfull</p>
          <div className="details-div">
            <div>
              <p>Member Id</p>
              <h3>{registerData}</h3>
            </div>
            <div>
              <p>Name</p>
              <h3>{formData.fullName}</h3>
            </div>
            <div>
              <p>Password</p>
              <h3>{formData.password}</h3>
            </div>
          </div>

          <div className="loma-fourth">
              <button onClick={()=>navigate(`/login?memberId=${registerData}&password=${formData.password}`)}>
                Dashboard
              </button>
            </div>
          
        </div>
        }


        </div>






    </>
  );
}

export default Register;
