import axios from "axios";

const apiURL = process.env.REACT_APP_API_URL;

export const makeAuthenticatedRequest = async (url, data) => {
  const memberData = JSON.parse(localStorage.getItem("memberData"));
  const config = {
    headers: { Authorization: `Bearer ${memberData.token}` },
  };

  try {
    const response = await axios.post(url, data, config);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 500) {
      throw new Error(error.response.data.error);
    } else if (error.response && error.response.status === 401) {
      localStorage.clear();
      window.location.reload();
      throw new Error("Unauthorized");
    } else {
      throw new Error("An error occurred.");
    }
  }
};

// Define the makeAuthenticatedGetRequest function
export const makeAuthenticatedGetRequest = async (url, params) => {
  const memberData = JSON.parse(localStorage.getItem("memberData"));

  const config = {
    headers: { Authorization: `Bearer ${memberData.token}` },
    params,
  };
  try {
    const response = await axios.get(url, config);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 500) {
      throw new Error(error.response.data.error);
    } else if (error.response && error.response.status === 401) {
      localStorage.clear();
      window.location.reload();
      throw new Error("Unauthorized");
    } else {
      throw new Error("An error occurred.");
    }
  }
};

// Define the getAllMembers service
export const getAllMembers = async () => {
  try {
    const queryParams = {}; // You can pass query parameters if needed
    const response = await makeAuthenticatedGetRequest(
      `${apiURL}admin/get-all-members`,
      queryParams
    );
    return response; // Assuming the response is an array of member data
  } catch (error) {
    throw new Error(`Error fetching all members: ${error.message}`);
  }
};

export const getPendingPayouts = async (walletName, status) => {
  try {
    const queryParams = { walletName, status }; // Pass walletName as a query parameter
    const response = await makeAuthenticatedGetRequest(
      `${apiURL}admin/get-payouts`,
      queryParams
    );
    return response.pendingTransactions; // Assuming the response contains an object with a "pendingTransactions" property
  } catch (error) {
    throw new Error(`Error fetching pending payouts: ${error.message}`);
  }
};

export const getProfileById = async (currentMemberId) => {
  const payLoad = {
    memberId: currentMemberId,
  };

  return await makeAuthenticatedRequest(`${apiURL}admin/get-profile`, payLoad);
};

export const getProfileAndMember = async (currentMemberId) => {
  const payLoad = {
    memberId: currentMemberId,
  };

  return await makeAuthenticatedRequest(
    `${apiURL}admin/get-member-and-profile`,
    payLoad
  );
};

export const updateProfile = async (updatedProfileData) => {
  console.log(updatedProfileData);

  const payload = {
    memberId: updatedProfileData.memberId,
    fullname: updatedProfileData.fullName,
    mobile: updatedProfileData.mobile,
    email: updatedProfileData.email,
    walletAddress: updatedProfileData.walletAddress,
    status: updatedProfileData.status,
  };

  return await makeAuthenticatedRequest(
    `${apiURL}admin/update-profile`,
    payload
  );
};

export const getTransactionReport = async (
  memberId,
  walletName,
  status,
  transactionRemark,
  debited,
  credited
) => {
  const params = {
    memberId: memberId,
    walletName: walletName,
    status: status,
    transactionRemark: transactionRemark,
    debited: debited,
    credited: credited,
  };

  try {
    return await makeAuthenticatedGetRequest(
      `${apiURL}admin/get-transaction-history`,
      params
    );
  } catch (error) {
    throw new Error(`Error fetching transaction report: ${error.message}`);
  }
};

// Define the getAssets service
export const getAssets = async () => {
  try {
    const response = await makeAuthenticatedGetRequest(
      `${apiURL}admin/get-assets`
    );
    return response; // Assuming the response contains asset data
  } catch (error) {
    throw new Error(`Error fetching assets: ${error.message}`);
  }
};

// Define the updateAssets service
export const updateAssets = async (updatedData) => {
  try {
    const response = await makeAuthenticatedRequest(
      `${apiURL}admin/update-assets`,
      updatedData
    );
    return response; // Assuming the response contains updated asset data
  } catch (error) {
    throw new Error(`Error updating assets: ${error.message}`);
  }
};

export const getLiveRate = async () => {
  // try {
  //   const response = await axios.get(
  //     "https://api.latoken.com/v2/ticker/ADVB/USDT"
  //   );

  //   if (response.data && response.data.lastPrice) {
  //     const liveRate = parseFloat(response.data.lastPrice);
  //     return liveRate;
  //   } else {
  //     throw new Error("Invalid response format");
  //   }
  // } catch (error) {
  //   console.error("Error fetching live rate:", error);
  //   throw error;
  // }

  try {
    const LiveRate = await makeAuthenticatedGetRequest(
      `${apiURL}admin/get-live-rate`
    );
    return LiveRate.tokenRateNumber;
  } catch (error) {
    console.error("Error fetching live rate:", error);
    throw error;
  }
};

// Define the updateAssets service
export const proceedUSDTPayout = async (payouts) => {
  try {
    const payload = {
      payouts: payouts,
    };

    const response = await makeAuthenticatedRequest(
      `${apiURL}admin/proceed-usdt-payout`,
      payload
    );
    return response;
  } catch (error) {
    throw new Error(`Error updating assets: ${error.message}`);
  }
};

// Define the updateAssets service
export const returnUSDTPayout = async (payouts) => {
  try {
    const payload = {
      payouts: payouts,
    };

    const response = await makeAuthenticatedRequest(
      `${apiURL}admin/return-usdt-payout`,
      payload
    );
    return response;
  } catch (error) {
    throw new Error(`Error updating assets: ${error.message}`);
  }
};

export const proceedADVBPayout = async (payouts) => {
  try {
    const payload = {
      payouts: payouts,
    };

    const response = await makeAuthenticatedRequest(
      `${apiURL}admin/proceed-advb-payout`,
      payload
    );
    return response;
  } catch (error) {
    throw new Error(`Error updating assets: ${error.message}`);
  }
};

export const returnADVBPayout = async (payouts) => {
  try {
    const payload = {
      payouts: payouts,
    };

    const response = await makeAuthenticatedRequest(
      `${apiURL}admin/return-advb-payout`,
      payload
    );
    return response;
  } catch (error) {
    throw new Error(`Error updating assets: ${error.message}`);
  }
};
