import React, { useEffect, useState } from "react";
import Table from "../../components/table/Table";

import Loader from "../../components/loader/Loader";
import { getTransactionReport } from "../../../services/Member";
import CustomTable from "../../components/custom-table/CustomTable";

function formatDate(dateString) {
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  return new Date(dateString).toLocaleDateString("en-GB", options);
}

const columns = [
  {
    Header: "S.no",
    accessor: "sn",
  },
  {
    Header: "Date",
    accessor: "date",
  },
  {
    Header: "Total Withdrawal (USDT)",
    accessor: "totalWithdrawal",
  },

  {
    Header: "10% Dividend (USDT)",
    accessor: "dividend",
  },
  {
    Header: "10% Topup (USDT)",
    accessor: "topup",
  },
  {
    Header: "5% Utility (USDT)",
    accessor: "utility",
  },

  {
    Header: "75% Net Payable",
    accessor: "netPayable",
  },

  {
    Header: "Status",
    accessor: "status",
  },

];

function UsdtReport() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const memberData = JSON.parse(localStorage.getItem("memberData"));

  const fetchTransactionHistory = async () => {
    setIsLoading(true);
    try {
      const transactionHistory = await getTransactionReport(
        memberData.memberId,
        "USDTWallet",
        "",
        "Fund Withdrawal - Fund Withdrawal",
        "",
        ""
      );

      const processedData = transactionHistory.result.transactions.map(
        (transaction, i) => {
          const typeAndRemark = transaction.transactionRemark.split(" - "); // Split by ' - ' to separate type and remark
          const type = typeAndRemark[0]; // Type is the first part
          const remark = typeAndRemark[1]; // Remark is the second part

          return {
            sn: i + 1,
            date: formatDate(transaction.date), // Use the capitalized first word as the Type
            totalWithdrawal: transaction.debitedAmount
              ? transaction.debitedAmount.toFixed(2)
              : 0,
            netPayable: transaction.debitedAmount
              ? (transaction.debitedAmount * 0.75).toFixed(2)
              : 0,
            dividend: transaction.debitedAmount
              ? (transaction.debitedAmount * 0.1).toFixed(2)
              : 0,
            topup: transaction.debitedAmount
              ? (transaction.debitedAmount * 0.1).toFixed(2)
              : 0,
            utility: transaction.debitedAmount
              ? (transaction.debitedAmount * 0.05).toFixed(2)
              : 0,
            Remark: remark,
            "status": transaction.status === "Completed" ? <p style={{color: "green"}}>Completed</p> : <p style={{color: "green"}}>{transaction.status}</p>,
          };
        }
      );

      setData(processedData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching transaction history:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTransactionHistory();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="deposit-history">
          <h1 className="main-heading">USDT Withdrawal</h1>
          <br />
          <br />

          <CustomTable
            data={data}
            columns={columns}
            heading="USDT Withdrawal report"
          />
        </div>
      )}
    </>
  );
}

export default UsdtReport;
