import React, { useEffect, useState } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import Table from "../../components/table/Table";

import Loader from "../../components/loader/Loader";
import { getTransactionReport } from "../../../services/Member";
import CustomTable from "../../components/custom-table/CustomTable";
import formatDate from "../../../utils/formatDate";

const columns = [
  {
    Header: "S.no",
    accessor: "sno",
  },
  {
    Header: "Payout Mode",
    accessor: "payoutMode",
  },
  {
    Header: "Withdrawal",
    accessor: "withdrawal",
  },
  {
    Header: "10% Admin Charge",
    accessor: "admincharge",
  },
  {
    Header: "After Charge",
    accessor: "afterCharge",
  },
  {
    Header: "Token Rate",
    accessor: "tokenRate",
  },
  {
    Header: "In USDT (USDT)",
    accessor: "inUSDT",
  },
  {
    Header: "Date",
    accessor: "date",
  },
  {
    Header: "Status",
    accessor: "status",
  },
];

function AdvbReport() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const memberData = JSON.parse(localStorage.getItem("memberData"));

  const fetchTransactionHistory = async () => {
    setIsLoading(true);
    try {
      const transactionHistory = await getTransactionReport(
        memberData.memberId,
        "ADVBWallet",
        "",
        "Withdrawal - Withdrawal Token",
        "",
        ""
      );
      console.log(transactionHistory);
      const processedData = transactionHistory.result.transactions.map(
        (transaction, i) => {
          const typeAndRemark = transaction.transactionRemark.split(" - "); // Split by ' - ' to separate type and remark
          const type = typeAndRemark[0]; // Type is the first part
          const remark = typeAndRemark[1]; // Remark is the second part

          return {
            sno: i + 1,
            payoutMode: "ADVB", // Use the capitalized first word as the Type
            withdrawal: transaction.debitedAmount
              ? transaction.debitedAmount.toFixed(2)
              : 0,
            admincharge: transaction.debitedAmount
              ? (transaction.debitedAmount * 0.1).toFixed(2)
              : 0,
            afterCharge: transaction.debitedAmount
              ? (transaction.debitedAmount * 0.9).toFixed(2)
              : 0,
            tokenRate: transaction.liveToken
              ? transaction.liveToken.toFixed(4)
              : 0,
            inUSDT:
              (transaction.debitedAmount * 0.9 * transaction.liveToken).toFixed(
                2
              ) || 0,
            remark: remark,
            date: formatDate(transaction.date),
            status:
              transaction.status === "Completed" ? (
                <a
                  style={{ color: "gold" }}
                  href={`https://bscscan.com/tx/${transaction.txn}`}
                  target="blank"
                >
                  Completed
                </a>
              ) : (
                transaction.status
              ),
          };
        }
      );

      setData(processedData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching transaction history:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTransactionHistory();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="deposit-history">
          <h1 className="main-heading">ADVB Withdrawal history</h1>
          <br />
          <br />

          <CustomTable
            data={data}
            columns={columns}
            heading="ADVB Withdrawal history"
          />
        </div>
      )}
    </>
  );
}

export default AdvbReport;
