import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  generateAndSaveOTP,
  getMemberbyId,
  getProfileById,
  getTransactionReport,
  getWalletById,
  getWithdrawalStatus,
  initPayout,
  verifyOTP
} from "../../../services/Member";
import Loader from "../../components/loader/Loader";
import "./style.css";
import { toast } from "react-toastify";
import Loader2 from "../../components/loader/Loader2";
import CustomTable from "../../components/custom-table/CustomTable";
import formatDate from "../../../utils/formatDate";

function AdvbPayout() {
  const [status, setStatus] = useState();
  const [currentBalance, setCurrentBalance] = useState(0);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState({
    fetchWalletDetails: false,
  });
  const [isOtpResendEnabled, setIsOtpResendEnabled] = useState(true);
  const [isPending, setIsPending] = useState(false);
  const [formData, setFormData] = useState({
    withdrawalAmount: "",
    walletAddress: "",
    otp: "",
    email: "",
  });
  const memberData = JSON.parse(localStorage.getItem("memberData"));


  const fetchWalletDetails = async () => {
    setIsLoading((prevLoadingState) => ({
      ...prevLoadingState,
      fetchWalletDetails: true,
    }));
    try {
      // Fetch wallet details using the appropriate service function
      const walletDetails = await getWalletById(memberData.memberId);
      const profileDetails = await getProfileById(memberData.memberId);
      const memberDetails = await getMemberbyId(memberData.memberId);
      const status = await getWithdrawalStatus("ADVB");
      setStatus(status);
      let wallet = walletDetails.result.wallet.ADVBWallet;

      // Update component state with fetched data
      setCurrentBalance(wallet.amount);
      setFormData({
        ...formData,
        walletAddress: profileDetails?.result.profile.walletAddress,
        email: memberDetails?.result.member.email,
      });

      setIsLoading((prevLoadingState) => ({
        ...prevLoadingState,
        fetchWalletDetails: false,
      }));    } catch (error) {
      console.error("Error fetching wallet details:", error);
      setIsLoading((prevLoadingState) => ({
        ...prevLoadingState,
        fetchWalletDetails: false,
      }));    }
  };

  

  const enableOtpResend = () => {
    setIsOtpResendEnabled(true);
  };

  const handleSubmit = async () => {
    setIsPending(true);
  
    try {
      if (!status) {
        throw new Error("Network is busy due to heavy traffic!");
      }
  
      if (
        !formData.withdrawalAmount ||
        !formData.walletAddress ||
        !formData.otp
      ) {
        throw new Error("Please fill out all fields.");
      }
  
      if (
        parseInt(formData.withdrawalAmount) <= 10 ||
        parseInt(formData.withdrawalAmount) > 50
      ) {
        throw new Error("Withdrawal amount must be greater than 10 and less than or equal to 50.");
      }
      
  
      if (formData.withdrawalAmount > currentBalance) {
        throw new Error("Not enough funds to withdraw.");
      }
  
      const verification = await verifyOTP(memberData.memberId, formData.otp);
      if (!verification) {
        toast.warn("Incorrect OTP.");
        setIsPending(false);
        return; // Return here to prevent further execution
      }
  
      await initPayout(memberData.memberId, "ADVBWallet", formData.withdrawalAmount);
      toast.success("Withdrawal request sent successfully!");
      fetchWalletDetails();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsPending(false);
    }
  };

  const sendOtp = async () => {
    setIsPending(true);

    try {
      if (isOtpResendEnabled) {
        await generateAndSaveOTP(memberData.memberId);
        toast.success(`OTP sent to ${formData.email}`);
      } else {
        toast.warn("You can resend OTP only after 2mins!");
        setIsPending(false);
        return;
      }
      setIsPending(false);

      // Disable OTP resending for 1 minute
      setIsOtpResendEnabled(false);
      setTimeout(enableOtpResend, 60000); // Enable OTP resending after 1 minute
    } catch (error) {
      console.log(error);
      setIsPending(false);
    }
  };

  useEffect(() => {
    fetchWalletDetails();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <>
      { isLoading.fetchWalletDetails ? (
        <Loader />
      ) : (
        <>
          {isPending && <Loader2 />}
          <div>
            <h1 className="main-heading">ADVB Payout</h1>
            <div className="adpa-zero">
              <div className="adze-left">
                <p>Withdrawal request</p>
              </div>
              <div className="adze-right">
                <h2 style={{ color: status ? "green" : "red" }}>
                  {status ? "Active" : "Network busy"}
                </h2>
              </div>
            </div>
            <div className="deposit-first">
              <div className="defi-top">
                <div className="defito-left">
                  <h2>Current Balance</h2>
                  <h1>{currentBalance.toFixed(2)} ADVB</h1>
                </div>
                <div className="defito-right">
                  <Link to="../advb-report">ADVB Withdrawal report</Link> <br /> <br />
                  <Link to="../advb-statement">ADVB Wallet statement</Link>
                </div>
              </div>
              <div className="defi-second"></div>
            </div>
            <div className="adpa-first">
              <div className="adpafi-top">
                <h2>Withdrawal ADVB</h2>
              </div>
              <div className="adpafi-body">
                <div className="input-group">
                  <label>Enter Amount</label>
                  <input
                    type="number"
                    placeholder="Enter Amount (Minimum 10 ADVB)"
                    name="withdrawalAmount"
                    value={formData.withdrawalAmount}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="input-group">
                  <label>Enter Wallet Address</label>
                  <input
                    type="text"
                    placeholder="Enter Wallet Address"
                    name="walletAddress"
                    value={formData.walletAddress}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="input-group">
                  <label>Enter OTP</label>
                  <input
                    type="text"
                    placeholder="Enter OTP"
                    name="otp"
                    value={formData.otp}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="button-group">
                  <button onClick={handleSubmit}>Submit</button>
                  <button onClick={sendOtp}>Send OTP</button>
                </div>
              </div>
            </div>

          </div>{" "}
        </>
      )}
    </>
  );
}

export default AdvbPayout;
