import { AiOutlineLeft, AiOutlineRight, AiOutlineSearch } from "react-icons/ai";
import React, { useState } from "react";

const Table = ({ data, fields, heading }) => {
  const rowsPerPageOptions = [5, 10, 20]; // Options for rows per page

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
  const [filter, setFilter] = useState("");

  const filteredData = data.filter((row) => {
    return Object.values(row).some(
      (fieldValue) =>
        fieldValue !== null &&
        fieldValue !== undefined &&
        fieldValue.toString().toLowerCase().includes(filter.toLowerCase())
    );
  });

  const pageCount = Math.ceil(filteredData.length / rowsPerPage);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className="table-div">
      <div className="table-top">
        <div className="table-heading">
          <h2>{heading}</h2>
        </div>
        <div className="table-search-input">
          <AiOutlineSearch />
          <input
            type="text"
            placeholder="Search"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        </div>
      </div>
      <div className="table-main">
        <div className="table-inner">
          {Object.keys(data).length === 0 ? (
            <p className="table-para">No data to show</p>
          ) : (
            <table responsive className="table-body">
              <tr>
                {fields.map((field) => (
                  <th key={field.key}>{field.label}</th>
                ))}
              </tr>
              {filteredData
                .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                .map((row) => (
                  <tr key={row.id}>
                    {fields.map((field) => {
                      if (field.key === "Status") {
                        const statusColor =
                          row[field.key].toLowerCase() === "active" ||
                          row[field.key].toLowerCase() === "completed"
                            ? "green"
                            : "red";
                        return (
                          <td
                            key={field.key}
                            style={{ color: statusColor, fontWeight: 600 }}
                          >
                            {row[field.key]}
                          </td>
                        );
                      } else {
                        let fieldValue = row[field.key];
                        if (
                          field.key !== "S. No" &&
                          typeof fieldValue === "number" &&
                          !isNaN(fieldValue) &&
                          fieldValue % 1 !== 0
                        ) {
                          fieldValue = fieldValue.toFixed(2);
                        }
                        return <td key={field.key}>{fieldValue}</td>;
                      }
                    })}
                  </tr>
                ))}
            </table>
          )}
        </div>
      </div>

      <div className="table-bottom">
        <div className="tb-left">
          <span>Rows per page:</span>
          <select value={rowsPerPage} onChange={handleChangeRowsPerPage}>
            {rowsPerPageOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div className="tb-right">
          <button
            onClick={() => handleChangePage(page - 1)}
            disabled={page === 0 || filteredData.length === 0}
          >
            <AiOutlineLeft />
          </button>
          <span>
            {page + 1} / {pageCount}
          </span>
          <button
            onClick={() => handleChangePage(page + 1)}
            disabled={page === pageCount - 1 || filteredData.length === 0}
          >
            <AiOutlineRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Table;
