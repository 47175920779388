import React, { useEffect, useState } from 'react'
import Menuitems from './Menuitem';
import { Link, useLocation } from 'react-router-dom';

function Sidebar2({ isSidebar, setIsSidebar }) {

    const [submenus, setSubmenus] = useState(
        Menuitems.filter((item) => item.submenus).map((item) => ({
          id: item.id,
          isOpen: false,
        }))
      );

      const location = useLocation();

    
      const handleKeyDown = (event, submenuId) => {
        if (event.key === "ArrowUp" || event.key === "ArrowDown") {
          event.preventDefault();
          toggleSubmenu(submenuId);
        }
      };
    
      const toggleSubmenu = (submenuId) => {
        setSubmenus((prevSubmenus) =>
          prevSubmenus.map((submenu) => ({
            ...submenu,
            isOpen: submenu.id === submenuId ? !submenu.isOpen : false,
          }))
        );
      };
    
      
      const handleCloseSubmenus = () => {
        setSubmenus(submenus.map((submenu) => ({ ...submenu, isOpen: false })));
      };
    
      useEffect(() => {
        // Log whenever the route changes
        console.log("Route changed:", location.pathname);
        handleCloseSubmenus();
        console.log(isSidebar)
        // setIsSidebar(false);
      }, [location]);

      
  return (
    <div className={`sidebar-left-links ${isSidebar ? 'active' : ''}`}>
          <ul className="nav-links">
            {Menuitems.map((item) => (
              <li
                className={`nav-link ${
                  submenus.find((submenu) => submenu.id === item.id)?.isOpen
                    ? "active"
                    : ""
                }`}
                key={item.id}
              >
                {item.submenus ? (
                  <React.Fragment>
                    <div
                      tabIndex="0"
                      onKeyDown={(event) => handleKeyDown(event, item.id)}
                      onClick={() => toggleSubmenu(item.id)}
                      aria-expanded={
                        submenus.find((submenu) => submenu.id === item.id)
                          ?.isOpen || false
                      }
                      aria-haspopup="true"
                    >
                      {item.label}
                      <span
                        className={`arrow ${
                          submenus.find((submenu) => submenu.id === item.id)
                            ?.isOpen
                            ? "open"
                            : ""
                        }`}
                      />
                    </div>
                    {submenus.find((submenu) => submenu.id === item.id)
                      ?.isOpen && (
                      <ul className="nav-sub-links">
                        {item.submenus.map((submenu) => (
                          <li className="nav-sub-link" key={submenu.id}>
                            <Link to={submenu.link} alt="img-alt">
                              <img src={submenu.img} />
                              <div className="sub-menu-link-right">
                                <h2>{submenu.label}</h2>
                                <p>{submenu.desc}</p>
                              </div>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </React.Fragment>
                ) : (
                  <Link to={item.link}>{item.label}</Link>
                )}
              </li>
            ))}
          </ul>
        </div>
  )
}

export default Sidebar2
