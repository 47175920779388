import React, { useEffect, useState } from 'react';
import Table from '../../components/table/Table';
import { getLiveRate, getPendingPayouts, getTransactionReport } from '../../../services/Admin';
import Loader from '../../components/loader/Loader';
import { getProfileById } from '../../../services/Admin';
import formatDate from '../../../utils/formatDate';
import CustomTableCheckbox from '../../components/custom-table/CustomTableCheckbox';
import CustomTable from '../../components/custom-table/CustomTable';

function UsdtPayoutFailed() {
  const [isLoading, setIsLoading] = useState(true);
  const [transactions, setTransactions] = useState([]); 

  const columns = [
    {
      Header: "S.no",
      accessor: "sno",
    },
    {
      Header: "Member Id",
      accessor: "memberId",
    },
    {
      Header: "Date",
      accessor: "date",
    },
    {
      Header: "Request Amount (USDT)",
      accessor: "requestAmountUSDT",
    },
    
    {
      Header: "Status",
      accessor: "status",
    },
  ];



  const fetchPendingPayouts = async () => {
    try {
      const transactions = await getTransactionReport("", "USDTWallet", "Failed", "Fund Withdrawal - Fund Withdrawal", "", "")
      console.log(transactions)
      const formatData = transactions.result.transactions.map((transaction, i) => {
        return{
          sno: i + 1,
          memberId: transaction.memberId ? transaction.memberId : "",
          date: transaction.date ? formatDate(transaction.date) : "",
          requestAmountUSDT: transaction.debitedAmount ? transaction.debitedAmount : "",
          netAmount: transaction.debitedAmount ? (transaction.debitedAmount * 0.75).toFixed(2) : "",
          liveRate: transaction.liveToken ? transaction.liveToken : "-",
          netAmountADVB: transaction.debitedAmount && transaction.liveToken ? ((transaction.debitedAmount * 0.75) / transaction.liveToken).toFixed(2) : "-",
          forDividend : transaction.debitedAmount ? (transaction.debitedAmount * 0.1).toFixed(2) : "",
          utility : transaction.debitedAmount ? (transaction.debitedAmount * 0.05).toFixed(2) : "",
          topup : transaction.debitedAmount ? (transaction.debitedAmount * 0.1).toFixed(2) : "",
          status: transaction.status === "Failed" ? <p style={{color: "red"}}>{transaction.status}</p> : "" ,
        }
    })

    setTransactions(formatData)
      
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching pending payouts:", error);
      setIsLoading(false);
    }
  };
  
  

  useEffect(() => {
    fetchPendingPayouts();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (<>
        <div className="usdt-payout">
          <h1 className="main-heading">USDT Payout Failed</h1>
          <br />
          <br />
          <CustomTable data={transactions} columns={columns} heading="USDT Failed Payout" />
        </div>
      </>
      )}
    </>
  );
}

export default UsdtPayoutFailed;
