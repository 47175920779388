import React, { useEffect, useState } from 'react';
import Table from '../../components/table/Table';
import { getLiveRate, getPendingPayouts, proceedUSDTPayout, returnUSDTPayout } from '../../../services/Admin';
import Loader from '../../components/loader/Loader';
import { getProfileById } from '../../../services/Admin';
import formatDate from '../../../utils/formatDate';
import CustomTableCheckbox from '../../components/custom-table/CustomTableCheckbox';
import { toast } from 'react-toastify';
import Loader2 from '../../components/loader/Loader2';

function UsdtPayout() {
  const [isLoading, setIsLoading] = useState(true);
  const [isPending, setIsPending] = useState(false);
  const [pendingPayouts, setPendingPayouts] = useState([]); // State to store pending payout data
  const [liveRate, setLiveRate] = useState();
  const [selectedRows, setSelectedRows] = useState([]);

  const columns = [

    {
      Header: 'S.no',
      accessor: 'sno',
    },
    {
      Header: 'Member Id',
      accessor: 'memberId',
    },
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Request Date',
      accessor: 'requestDate',
    },
    {
      Header: 'Request Amount (USDT)',
      accessor: 'requestAmountUSDT',
    },
    {
      Header: 'Net Amount (USDT)',
      accessor: 'netAmount',
    },
    {
      Header: 'Net Amount (ADVB)',
      accessor: 'netAmountADVB',
    },
    {
      Header: '10% Dividend (USDT)',
      accessor: 'forDividend',
    },
    {
      Header: '5% Utility (USDT)',
      accessor: 'utility',
    },
    {
      Header: '10% Top up (USDT)',
      accessor: 'topup',
    },
    {
      Header: 'Wallet Address',
      accessor: 'walletAddress',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
  ];

  const handleSelectedRows = (selectedFlatRows) => {
    setSelectedRows(selectedFlatRows);
  };

  const handleApproved = async () => {
    setIsPending(true)
    const formattedSelectedRows = selectedRows.map((selectedRow) => {
      const rowData = selectedRow.original;
      const formattedRow = {};
  
      if (rowData.walletAddress) {
        formattedRow['_id'] = rowData._id;
        formattedRow['memberId'] = rowData.memberId;
        formattedRow['walletAddress'] = rowData.walletAddress;
        formattedRow['netAmountADVB'] = rowData.netAmountADVB
        formattedRow['requestAmountUSDT'] = rowData.requestAmountUSDT
        formattedRow['tokenRate'] = liveRate
      } else {
        // If walletAddress is missing, show a toast
        toast.error(`Member with ID ${rowData.memberId} doesn't have a walletAddress.`);
        setIsPending(false)
        return 
      }
      return formattedRow;
    });
  
    const allFormattedRows = [].concat(...formattedSelectedRows);
  
    try {
      if (allFormattedRows.length > 0) {
        const payouts = await proceedUSDTPayout(allFormattedRows);
        toast.success(`${payouts.processedPayouts.length} transactions successful!`)
        window.location.reload();
      } else {
        toast.warn("No rows with wallet address!")
      }
    } catch (error) {
      console.log(error);
    }
    setIsPending(false)
  };
  
  
  
  
  const handleCancel = async () => {
    setIsPending(true)
    const formattedSelectedRows = selectedRows.map((selectedRow) => {
      const rowData = selectedRow.original;
      const formattedRow = {};
  
      if (rowData.walletAddress) {
        formattedRow['_id'] = rowData._id;
      } else {
        toast.error(`Member with ID ${rowData.memberId} doesn't have a walletAddress.`);
      }
  
      return formattedRow;
    });
  
    const allFormattedRows = [].concat(...formattedSelectedRows);
  
    try {
      if (allFormattedRows.length > 0) {
        const payouts = await returnUSDTPayout(allFormattedRows);
        toast.success(`${payouts.processedPayouts.length} transactions Return!`)
        window.location.reload();
      } else {
        toast.warn("No rows with wallet address!")
      }
    } catch (error) {
      console.log(error);
    }
    setIsPending(false)
  };
  
  
  
  
  

  const fetchPendingPayouts = async () => {
    try {
      // Fetch pending payouts data
      const payoutData = await getPendingPayouts('USDTWallet', 'Pending');
      const liveRate = await getLiveRate();
      setLiveRate(liveRate);

      // Create an array of promises for fetching profile data
      const profilePromises = payoutData.map(async (transaction) => {
        const profileData = await getProfileById(transaction.memberId);
        return profileData;
      });

      // Wait for all profile data requests to complete
      const profiles = await Promise.all(profilePromises);

      // Format the pending payouts data
      const formattedData = payoutData.map((transaction, i) => {
        const profileData = profiles[i];
        const currrentProfile = profileData.result.profile;

        // Extract relevant fields and format the data
        const formattedTransaction = {
          _id: transaction._id,
          sno: i + 1,
          memberId: transaction.memberId || 'None',
          name: currrentProfile.fullname || 'None',
          requestDate: formatDate(transaction.date),
          requestAmountUSDT: transaction.debitedAmount
            ? transaction.debitedAmount.toFixed(2)
            : 0,
          netAmountADVB: transaction.debitedAmount
            ? ((transaction.debitedAmount * 0.75) / liveRate).toFixed(2)
            : 0,
          netAmount: transaction.debitedAmount
            ? (transaction.debitedAmount * 0.75).toFixed(2)
            : 0,
          forDividend: transaction.debitedAmount
            ? (transaction.debitedAmount * 0.10).toFixed(2)
            : 0,
          utility: transaction.debitedAmount
            ? (transaction.debitedAmount * 0.05).toFixed(2)
            : 0,
          topup: transaction.debitedAmount
            ? (transaction.debitedAmount * 0.10).toFixed(2)
            : 0,
          walletAddress: currrentProfile.walletAddress || '',
          status: transaction.status === "Pending" ? <p style={{color: "red"}}>{transaction.status}</p> : "" ,
          date: new Date(transaction.date).toLocaleDateString() || 'None',
          // Add fields from profileData as needed
        };

        return formattedTransaction;
      });

      setPendingPayouts(formattedData); // Update the state variable with formatted payout data
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching pending payouts:', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPendingPayouts();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
        {isPending && <Loader2 />}
          <div className="usdt-payout">
            <h1 className="main-heading">USDT Payout Requests</h1>
            <br />
            <div className="button-group">
              <button onClick={handleCancel} style={{background: "red"}}>Return withdrawal Amount</button>
              <button onClick={handleApproved} style={{background: "green"}}>Approve selected Request</button>
            </div>
            <br />
            <CustomTableCheckbox
              data={pendingPayouts}
              columns={columns}
              heading="USDT Pending Requests"
              onSelectedRows={handleSelectedRows}
            />
          </div>
        </>
      )}
    </>
  );
}

export default UsdtPayout;
