import React, { useEffect, useState } from "react";
import Table from "../../components/table/Table";

import Loader from "../../components/loader/Loader";
import { getTransactionReport } from "../../../services/Member";
import CustomTable from "../../components/custom-table/CustomTable";


const column = [
  {
    Header: "S.No",
    accessor: "sn",
  },
  {
    Header: "Date",
    accessor: "date",
  },
  {
    Header: "Credit Amount",
    accessor: "creditedAmount",
  },
  {
    Header: "Debit Amount",
    accessor: "debitAmount",
  },
  {
    Header: "Type",
    accessor: "type",
  },
  {
    Header: "Remark",
    accessor: "remark",
  }
];

function RetopupHistory() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const memberData = JSON.parse(localStorage.getItem("memberData"));

  const fetchTransactionHistory = async () => {
    setIsLoading(true);
    try {
      const transactionHistory = await getTransactionReport(
        memberData.memberId,
        'retopupWallet',
        '',
        '',
        '',
        ''
      );
      
      const processedData = transactionHistory.result.transactions.map((transaction, i) => {
        const typeAndRemark = transaction.transactionRemark.split(' - '); // Split by ' - ' to separate type and remark
        const type = typeAndRemark[0]; // Type is the first part
        const remark = typeAndRemark[1]; // Remark is the second part
         
        return {
          "sn": i + 1,
          "date": new Date(transaction.date).toLocaleDateString(),
          "creditedAmount": transaction.creditedAmount ? transaction.creditedAmount.toFixed(2) : 0,
          "debitAmount": transaction.debitedAmount ? transaction.debitedAmount.toFixed(2) : 0,
          "type": type, // Use the capitalized first word as the Type
          "remark": remark,
        };
        
      });
  
      setData(processedData);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching transaction history:', error);
      setIsLoading(false);
    }
  };
  

  useEffect(() => {
    fetchTransactionHistory();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="deposit-history">
          <h1 className="main-heading">Re TopUp Wallet Report</h1>
          <br />
          <br />

          <CustomTable data={data} columns={column} heading="Re TopUp Wallet Transaction" />
        </div>
      )}
    </>
  );
}

export default RetopupHistory;
