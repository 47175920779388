import React from 'react'
import loader from '../../assets/images/loader.gif'
import './style.css'

function Loader() {
  return (
    <div className='loader'>
      <img src={loader} alt='loader' />
    </div>
  )
}

export default Loader
