import React, { useEffect, useState } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import Table from "../../components/table/Table";
import {
  getCompletedDepositHistory,
  getWalletById,
} from "../../../services/Member";
import Loader from "../../components/loader/Loader";
import formatDate from "../../../utils/formatDate";
import CustomTable from "../../components/custom-table/CustomTable";



function DepositHistory() {
  const [currentBalance, setCurrentBalance] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const memberData = JSON.parse(localStorage.getItem("memberData"));
  const [data, setData] = useState([]);

  const column = [
    {
      Header: "S.No",
      accessor: "sn",
    },
    {
      Header: "Date",
      accessor: "date",
    },
    {
      Header: "Credit Amount",
      accessor: "creditedAmount",
    },
    {
      Header: "Debit Amount",
      accessor: "debitAmount",
    },
    {
      Header: "Mode",
      accessor: "mode",
    },
    {
      Header: "Remark",
      accessor: "remark",
    },
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "BSC Scan",
      accessor: "bscScan",
    },
  ];

  // fetch wallet details
  // fetch wallet details
  const fetchWalletDetails = async () => {
    setIsLoading(true)
    try {
      const walletDetails = await getWalletById(memberData.memberId);
      setCurrentBalance(walletDetails.result.wallet.ADVBDeposit);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching user details:", error);
      setIsLoading(false);
    }
  };

  const fetchDepositHistory = async () => {
    setIsLoading(true)

    try {
      const depositHistory = await getCompletedDepositHistory(memberData.memberId);
      console.log(depositHistory)
      const newArr = depositHistory.result.completedDeposits.map((entry, index) => ({
        "sn": index + 1,
        "date": formatDate(entry.timeOfEvent),
        "debitAmount": entry.depositAmount ? entry.depositAmount : 0,
        "creditedAmount": entry.withdrawalAmount ? entry.withdrawalAmount : 0,
        "mode": entry.mode,
        "remark": entry.depositAmount > 0 ? "Package Buy" : "Deposit Tokens",
        "status": entry.status,
        "bscScan": entry.withdrawalAmount != 0 ? <a style={{color: "gold"}} href={`https://bscscan.com/tx/${entry.txn}`} target="blank">BSC Transaction</a> : "-"
      }));
      setData(newArr);
      setIsLoading(true)

    } catch (error) {
      console.error("Error fetching deposit history:", error);
      setIsLoading(true)

    }
  };

  useEffect(() => {
    fetchDepositHistory();
    fetchWalletDetails();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="deposit-history">
          <h1 className="main-heading">Deposit Wallet History</h1>
          <div className="deposit-first">
            <div className="defi-top">
              <div className="defito-left">
                <h2>Wallet Balance</h2>
                <h1>$ {currentBalance ? (currentBalance).toFixed(2)  : 0}</h1>
              </div>
              <div className="defito-right">
                <Link to="../deposit">Deposit Fund</Link>
              </div>
            </div>
            <div className="defi-second"></div>
          </div>

          <CustomTable data={data} columns={column} heading="Deposit History" />
        </div>
      )}
    </>
  );
}

export default DepositHistory;
