import React, { useEffect, useState } from "react";
import Loader from "../../components/loader/Loader";
import CustomTable from "../../components/custom-table/CustomTable";
import formatDate from "../../../utils/formatDate";
import { getIncomeStatementByType } from "../../../services/Member";
import { useLocation } from "react-router-dom";

const columns = [
  {
    Header: "S.no",
    accessor: "sno",
  },
  {
    Header: "Credit",
    accessor: "credit",
  },
  {
    Header: "Debit",
    accessor: "debit",
  },
  {
    Header: "Date",
    accessor: "date",
  },
  {
    Header: "Type",
    accessor: "type",
  },
  {
    Header: "Remark",
    accessor: "remark",
  },
  {
    Header: "Status",
    accessor: "status",
  },
];

function UsdtStatement() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [incomeType, setIncomeType] = useState(""); // Dynamic incomeType state
  const memberData = JSON.parse(localStorage.getItem("memberData"));
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search);


  // Define dynamic options for the select element
  const incomeTypeOptions = [
    { value: "", label: "Select Income Type" },
    // { value: "AdminDeduction", label: "Admin Deduction" },
    // { value: "Closing Amount", label: "Closing Amount" },
    // { value: "diposit", label: "Deposit" },
    { value: "Roi Bonus", label: "Roi Bonus" },
    { value: "Roi Level Bonus", label: "Growth Level" },
    { value: "Mature Staked", label: "Mature Staked" },
    { value: "Direct Bonus", label: "Direct Bonus" },
    { value: "Dividend Bonus", label: "Dividend Bonus" },
    { value: "Booster Cashback", label: "Booster Cashback" },
    { value: "Reward Bonus", label: "Reward Bonus" },
    { value: "Mentorship Bonus", label: "Mentorship Bonus" },
    { value: "Fund Withdrawal", label: "Fund Withdrawal" },
    // Add other package options here
  ];

  const fetchTransactionHistory = async () => {
    setIsLoading(true);
    try {
      const transactionHistory = await getIncomeStatementByType(
        memberData.memberId,
        incomeType
      );

      const processedData = transactionHistory.incomeStatements.map(
        (transaction, i) => {
          const typeAndRemark = transaction.transactionRemark.split(" - "); // Split by ' - ' to separate type and remark
          const type = typeAndRemark[0]; // Type is the first part
          const remark = typeAndRemark[1]; // Remark is the second part

          return {
            sno: i + 1,
            credit: transaction.creditedAmount
              ? transaction.creditedAmount.toFixed(2)
              : 0,
            debit: transaction.debitedAmount
              ? transaction.debitedAmount.toFixed(2)
              : 0,
            date: formatDate(transaction.date),
            type: type || "",
            remark: remark || "",
            status: transaction.status ? (
              transaction.status == "Completed" ? (
                <p style={{ color: "Green" }}>{transaction.status}</p>
              ) : (
                <p>{transaction.status}</p>
              )
            ) : (
              ""
            ),
          };
        }
      );

      setData(processedData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching transaction history:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (incomeType) {
      fetchTransactionHistory();
    }
  }, [incomeType]);

  useEffect(() => {
    const paramValue = queryParams.get('incomeType');

    setIncomeType(paramValue)
  }, []);

  // Handle select change and trigger fetching
  const handleSelectChange = (e) => {
    setIncomeType(e.target.value);
    setIsLoading(true); // Set isLoading to true when incomeType changes
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="deposit-history">
          <h1 className="main-heading">Income Statements</h1>
          <div className="input-group">
            <label>Select Income type</label>
            <select value={incomeType} onChange={handleSelectChange}>
              {incomeTypeOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          <br />
          <CustomTable
            data={data}
            columns={columns}
            heading="Income statement"
          />
        </div>
      )}
    </>
  );
}

export default UsdtStatement;
