import axios from "axios";

const apiURL = process.env.REACT_APP_API_URL; // Make sure you have the environment variable set correctly

export const loginMember = async (memberId, password) => {
  try {
    const response = await axios.post(`${apiURL}auth/login`, {
      memberId: memberId,
      password: password,
    });

    if (response.message === "Passwords do not match") {
      throw new Error("Invalid password");
    }

    if (response.status === 200) {
      return response.data.data;
    }
  } catch (error) {
    throw new Error("Invalid Email or Password!");
  }
};


export const generateAndSaveOTP = async (memberId) => {
  try {
    const response = await axios.post(`${apiURL}auth/generate-otp`, {
      memberId: memberId,
    });

    if (response.status === 200) {
      return response.data.email;
    }
  } catch (error) {
    if(error.response.data.error === "Invalid memberId"){
      throw new Error("Invalid Member Id");
    } else {
      throw new Error("Something went wrong invalid memberId!");

    }
  }
};

export const forgotPassword = async (memberId, enteredOtp, newPassword) => {
  try {
    const response = await axios.post(`${apiURL}auth/forgot-password`, {
      memberId: memberId,
      otp: enteredOtp,
      newPassword: newPassword,
    });

    if (response.status === 200) {
      return response.data.message; // Password changed successfully
    }
  } catch (error) {
    throw new Error("Invalid OTP or Error changing password!");
  }
};