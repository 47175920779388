import React, { useEffect, useState } from "react";
import logo from "../../assets/images/main-logo.png";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { loginMember } from "../../../services/Auth";
import jwtDecode from "jwt-decode";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

function Login() {
  const [memberId, setMemberId] = useState("");
  const [password, setPassword] = useState("");
  const [formData, setFormData] = useState({
    memberId: "",
    password: ""
  })
  const [isPending, setIsPending] = useState(false); // Add isPending state
  const navigate = useNavigate();
  const [visibile, setVisible] = useState(false);

useEffect(() => {
  const queryParams = new URLSearchParams(window.location.search);
  const queryMemberId = queryParams.get("memberId");
  const queryPassword = queryParams.get("password");

  if (queryMemberId && queryPassword) {
    // Both query parameters are present, so set the state variables
    setFormData({
      memberId: queryMemberId,
      password: queryPassword
    })
  
  }
}, []);

useEffect(() => {
  // Check if both parameters are present and automatically trigger login
  if (formData.memberId !== "" && formData.password !== "") {
    handleLogin(formData.memberId, formData.password);
  }
}, [formData]);

 
  

  const handleLogin = async (paramMemberId, paramPassword) => {

    if (paramMemberId === "" || paramPassword === "") {
      toast.warn("All fields are required!");
      return;
    }

    // Set isPending to true before making the Axios request
    setIsPending(true);

    try {
      const memberData = await loginMember(paramMemberId.toUpperCase(), paramPassword);
      const decodedToken = jwtDecode(memberData.token);

      if (memberData) {
        if (decodedToken.userType === "admin") {
          navigate("/admin/dashboard");
        } else if (decodedToken.userType === "member") {
          navigate("/member/dashboard");
        }
      }

      toast.success("Login successful!");

      localStorage.setItem("memberData", JSON.stringify(memberData));
      // console.log(decodedToken);
      setIsPending(false);
    } catch (error) {
      toast.error(error.message);
    } finally {
      // Set isPending back to false after the Axios request is completed
      setIsPending(false);
    }
  };

  return (
    <div className="login">
      <div className="login-first">
        <img className="login-logo" alt="logo-i" src={logo} />
      </div>
      <div className="login-main">
        <div className="loma-first">
          <h2>Login</h2>
          <Link to="/register">Don't have an account?</Link>
        </div>
          <div className="loma-second">
            <div className="input-group-2">
              <label>Member Id</label>
              <input
                type="text"
                placeholder="AV12345"
                value={memberId}
                onChange={(e) => setMemberId(e.target.value)}
                required
              />
            </div>
            <div className="input-group-2 eye-parent">
              <label>Password</label>
              <input
                type={visibile ? "text" : "password"}
                placeholder="Enter Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <div className="eye" onClick={()=>setVisible((prev)=>!prev)}>
                {
                  visibile ? <AiFillEye /> : <AiFillEyeInvisible />
                }
              </div>
            </div>
          </div>

          <div className="loma-third">
            <Link to="/forgot-password">Forgot Password!</Link>
          </div>
          <div className="loma-fourth">
            {/* Disable the button and change text when isPending is true */}
            <button type="submit" onClick={() => handleLogin(memberId, password)} disabled={isPending}>
              {isPending ? "Loading..." : "Login"}
            </button>
          </div>
      </div>
    </div>
  );
}

export default Login;
