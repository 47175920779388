// MenuItems.js
const Menuitems = [
  {
    id: 1,
    label: "Dashboard",
    link: "/member/dashboard",
  },
  {
    id: 2,
    label: "Deposit",
    submenus: [
      {
        id: 1,
        label: "Deposit",
        link: "/member/deposit",
        img: "https://res.cloudinary.com/dev6cpp4u/image/upload/v1694688143/1_xwp63i.png",
        desc: "Deposit USDT to buy a package."
      },
      {
        id: 2,
        label: "Deposit History",
        link: "/member/deposit-history",
        img: "https://res.cloudinary.com/dev6cpp4u/image/upload/v1694688143/1_xwp63i.png",
        desc: "Deposit wallet report."
      }
    ],
  },
  {
    id: 3,
    label: "Invest",
    submenus: [
      {
        id: 1,
        label: "Buy Package",
        link: "/member/buy-package",
        img: "https://res.cloudinary.com/dev6cpp4u/image/upload/v1694688143/2_qbnno2.png",
        desc: "Purchase package from deposit wallet."
      },
      {
        id: 2,
        label: "Invest history",
        link: "/member/investment-history",
        img: "https://res.cloudinary.com/dev6cpp4u/image/upload/v1694688143/2_qbnno2.png",
        desc: "Report for package status and profit."
      },
      {
        id: 3,
        label: "Re Topup",
        link: "/member/retopup",
        img: "https://res.cloudinary.com/dev6cpp4u/image/upload/v1694688143/2_qbnno2.png",
        desc: "Reactivate your ID from retop up balance."
      },
    ],
  },
  {
    id: 4,
    label: "Payout",
    submenus: [
      {
        id: 1,
        label: "ADVB Payout",
        link: "/member/advb-payout",
        img: "https://res.cloudinary.com/dev6cpp4u/image/upload/v1694688143/3_w8memu.png",
        desc: "ADVB Wallet, check report and withdraw ADVB token."
      },
      {
        id: 2,
        label: "USDT Payout",
        link: "/member/usdt-payout",
        img: "https://res.cloudinary.com/dev6cpp4u/image/upload/v1694688143/3_w8memu.png",
        desc: "USDT Wallet, check report and withdraw USDT."
      },
      {
        id: 3,
        label: "ROI stake",
        link: "/member/roi-stack",
        img: "https://res.cloudinary.com/dev6cpp4u/image/upload/v1694688143/3_w8memu.png",
        desc: "Balance and report for ROI wallet."
      },
      
    ],
  },
  
  {
    id: 5,
    label: "Rewards",
    submenus: [
      
      {
        id: 2,
        label: "Reward Bonus",
        link: "/member/reward-bonus",
        img: "https://res.cloudinary.com/dev6cpp4u/image/upload/v1690360434/quicklinks-4_jkq5o1.png",
        desc: "Report for reward bonus."
      },
     
      {
        id: 3,
        label: "Royalty Bonus",
        link: "/member/royalty-bonus",
        img: "https://res.cloudinary.com/dev6cpp4u/image/upload/v1690360434/quicklinks-4_jkq5o1.png",
        desc: "Report for royalty level reached."
      },
     
      {
        id: 4,
        label: "Income Statement",
        link: "/member/working-income",
        img: "https://res.cloudinary.com/dev6cpp4u/image/upload/v1690360434/quicklinks-3_oncizl.png",
        desc: "Direct , Reward , Royalty , Mentorship , Booster cashback, Dividend bonus statements."
      },
    
     

    ],
  },
  {
    id: 6,
    label: "Profile",
    submenus: [
      
      {
        id: 1,
        label: "Profile",
        link: "/member/profile",
        img: "https://res.cloudinary.com/dev6cpp4u/image/upload/v1690360434/quicklinks-2_sse9rj.png",
        desc: "Update name, profile, wallet address."
      },
      // {
      //   id: 2,
      //   label: "Change Password",
      //   link: "/member/change-password",
      //   img: "https://res.cloudinary.com/dev6cpp4u/image/upload/v1690360434/quicklinks-2_sse9rj.png",
      //   desc: "Change password."
      // },
      {
        id: 3,
        label: "Team Business",
        link: "/member/team-business",
        img: "https://res.cloudinary.com/dev6cpp4u/image/upload/v1690360434/quicklinks-1_q0bnot.png",
        desc: "Report for referrals and team business."
      },
      {
        id: 4,
        label: "Logout",
        link: "/logout",
        img: "https://res.cloudinary.com/dev6cpp4u/image/upload/v1690360434/quicklinks-2_sse9rj.png",
        desc: "Logout from all accounts."
      },

    ],
  },
  

];

export default Menuitems;
