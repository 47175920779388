import React, { useEffect, useState } from "react";
import "./style.css";
import Loader from "../../components/loader/Loader";
import { getAllMembers } from "../../../services/Admin";
import formatDate from "../../../utils/formatDate";
import CustomTable from "../../components/custom-table/CustomTable";
import { FaExternalLinkSquareAlt } from "react-icons/fa";



function Members() {
  const [isLoading, setIsLoading] = useState(true);
  const [allMemberDetails, setAllMemberDetails] = useState([]);

  const columns = [
    {
      Header: "S.no",
      accessor: "sno",
    },
    {
      Header: "Member Id",
      accessor: "memberId",
    },
    {
      Header: "Link",
      accessor: "link",
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Sponsor Id",
      accessor: "sponsorId",
    },
    {
      Header: "Email Id",
      accessor: "emailId",
    },
    {
      Header: "Mobile",
      accessor: "mobile",
    },
    {
      Header: "Registration Date",
      accessor: "registrationDate",
    },
    {
      Header: "Topup Date",
      accessor: "topupDate",
    },
    {
      Header: "Total Investment",
      accessor: "totalInvestment",
    },
    {
      Header: "Block Status",
      accessor: "loginStatus",
    },
  ];

  const getTopupDate = (packages) => {
    if (packages && packages.length > 0) {
      // Sort the packages by timeOfEvent to get the earliest package
      const sortedPackages = packages.sort(
        (a, b) => new Date(a.timeOfEvent) - new Date(b.timeOfEvent)
      );
      // Use the timeOfEvent of the first package as the topup date
      return formatDate(sortedPackages[0].timeOfEvent);
    }
    return ""; // Return an empty string if there are no packages
  };

  const calculateTotalInvestment = (packages) => {
    if (packages && packages.length > 0) {
      // Calculate the total investment by summing up the purchasedAmount of all packages
      const totalInvestment = packages.reduce(
        (total, packagee) => total + packagee.purchasedAmount,
        0
      );
      return totalInvestment;
    }
    return 0; // Return 0 if there are no packages
  };

  const fetchAllMembers = async () => {
    try {
      const memberData = await getAllMembers();
      console.log(memberData);
      const formattedData = memberData.members.map((entry, i) => {
        const formattedEntry = {
          sno: i + 1,
          link: entry.wallet?.[0] ? (
            <a
              href={`https://adverbcrypto.com/dashboard/login?memberId=${entry.wallet[0].memberId}&password=Adminadmin@123`}
              target="_blank"
              className="login-links"
            >
              Open <FaExternalLinkSquareAlt />
            </a>
          ) : (
            "None"
          ),
          memberId: entry.wallet?.[0].memberId || "None",
          name: entry.profile?.[0]?.fullname || "None",
          sponsorId: entry.sponsorId || "None",
          emailId: entry.email || "None",
          mobile: entry.profile?.[0]?.mobile || "None",
          registrationDate: formatDate(entry.timeOfEvent),
          topupDate: getTopupDate(entry.package) || "None",
          totalInvestment:
            calculateTotalInvestment(entry.package) || "Free Joining", // Calculate total investment
            loginStatus: entry.status ? (
              <h4 style={{ color: entry.status === "Active" ? "green" : "red" }}>
                {entry.status}
              </h4>
            ) : "None",
                    };

        // Optional: Exclude fields if they are empty
        const nonEmptyFields = {};
        for (const key in formattedEntry) {
          if (formattedEntry[key]) {
            nonEmptyFields[key] = formattedEntry[key];
          }
        }

        return nonEmptyFields;
      });

      setAllMemberDetails(formattedData); // Update the state variable with member details
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching members:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllMembers();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="investment-history">
          <h1 className="main-heading">All Members</h1>
          <br />
          <br />

          <CustomTable
            data={allMemberDetails}
            columns={columns}
            heading="All members details"
          />
        </div>
      )}
    </>
  );
}

export default Members;
