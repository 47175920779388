import React, { useEffect, useState } from 'react';
import Table from '../../components/table/Table';
import { getLiveRate, getPendingPayouts, getTransactionReport } from '../../../services/Admin';
import Loader from '../../components/loader/Loader';
import { getProfileById } from '../../../services/Admin';
import formatDate from '../../../utils/formatDate';
import CustomTableCheckbox from '../../components/custom-table/CustomTableCheckbox';
import CustomTable from '../../components/custom-table/CustomTable';

function AdvbPayoutApproved() {
  const [isLoading, setIsLoading] = useState(true);
  const [transactions, setTransactions] = useState([]); 

  const columns = [
    {
      Header: "S.no",
      accessor: "sno",
    },
    {
      Header: "Member Id",
      accessor: "memberId",
    },
    {
      Header: "Date",
      accessor: "date",
    },
    {
      Header: "Request Amount (ADVB)",
      accessor: "requestAmountADVB",
    },
    {
      Header: "90% Net Amount (ADVB)",
      accessor: "netAmountADVB",
    },
    {
      Header: "10% Admin (USDT)",
      accessor: "admincharges",
    },
    {
      Header: "Status",
      accessor: "status",
    },
  ];



  const fetchPendingPayouts = async () => {
    try {
      const transactions = await getTransactionReport("", "ADVBWallet", "Completed", "Withdrawal - Withdrawal Token", "", "")
      console.log(transactions)
      const formatData = transactions.result.transactions.map((transaction, i) => {
        return{
          sno: i + 1,
          memberId: transaction.memberId ? transaction.memberId : "",
          date: transaction.date ? formatDate(transaction.date) : "",
          requestAmountADVB: transaction.debitedAmount ? transaction.debitedAmount : "",
          netAmountADVB: transaction.debitedAmount ? (transaction.debitedAmount * 0.90).toFixed(2) : "",
          admincharges: transaction.debitedAmount ? (transaction.debitedAmount * 0.10).toFixed(2) : "",
          status:
              transaction.status === "Completed" ? (
                <a
                  style={{ color: "gold" }}
                  href={`https://bscscan.com/tx/${transaction.txn}`}
                  target="blank"
                >
                  Completed
                </a>
              ) : (
                transaction.status
              ),
        }
    })

    setTransactions(formatData)
      
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching pending payouts:", error);
      setIsLoading(false);
    }
  };
  
  

  useEffect(() => {
    fetchPendingPayouts();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (<>
        <div className="usdt-payout">
          <h1 className="main-heading">USDT Payout Approved</h1>
          <br />
          <br />
          <CustomTable data={transactions} columns={columns} heading="USDT Payouts Approved" />
        </div>
      </>
      )}
    </>
  );
}

export default AdvbPayoutApproved;
