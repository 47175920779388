import React, { useState, useEffect } from "react";
import Loader from "../../components/loader/Loader";
import { getRewardStatus, getRoyaltyStatus } from "../../../services/Member";

function formatDate(dateString) {
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  return new Date(dateString).toLocaleDateString('en-GB', options);
}

function RoyaltyBonus() {
  const [isLoading, setIsLoading] = useState(true);
  const [rewardBonus, setRewardBonus] = useState();
  const memberData = JSON.parse(localStorage.getItem("memberData"));

  const fields = [
    { key: "Sl. No", label: "SL. No" },
    { key: "Team Business", label: "Team Business" },
    { key: "Direct Id", label: "Direct Id" },
    { key: "Daily Bonus", label: "Daily Bonus (100 Days)" },
    { key: "Achieve Date", label: "Achieve Date" },
    { key: "Days Completed", label: "Days Completed" },
    { key: "Gift", label: "Gift" },
    { key: "Status", label: "Status" },
    // Add more fields as needed
  ];

  // Sample data
  const sampleData = [
    {
      "Sl. No": 1,
      "Team Business": "$ 1000",
      "Direct Id": "3",
      "Daily Bonus": "$ 1",
      "Achieve Date" : "",
      "Days Completed": "",
      "Gift": "Coupon",
      "Status": ""
    },
    {
      "Sl. No": 2,
      "Team Business": "$ 2500",
      "Direct Id": "1",
      "Daily Bonus": "$ 2",
      "Achieve Date" : "",
      "Days Completed": "",
      "Gift": "L.D.P",
      "Status": ""
    },
    {
      "Sl. No": 3,
      "Team Business": "$ 6000",
      "Direct Id": "1",
      "Daily Bonus": "$ 5",
      "Achieve Date" : "",
      "Days Completed": "",
      "Gift": "GOA Tour",
      "Status": ""
    },
    {
      "Sl. No": 4,
      "Team Business": "$ 15000",
      "Direct Id": "1",
      "Daily Bonus": "$ 10",
      "Achieve Date" : "",
      "Days Completed": "",
      "Gift": "$250 For Mobile Fund",
      "Status": ""
    },
    {
      "Sl. No": 5,
      "Team Business": "$ 40,000",
      "Direct Id": "1",
      "Daily Bonus": "$ 30",
      "Achieve Date" : "",
      "Days Completed": "",
      "Gift": "Thailand Tour Only 1 Person",
      "Status": ""
    },
    {
      "Sl. No": 6,
      "Team Business": "$ 1,00,000",
      "Direct Id": "1",
      "Daily Bonus": "$ 100",
      "Achieve Date" : "",
      "Days Completed": "",
      "Gift": "Dubai Tour (2 Person)",
      "Status": ""
    },
    {
      "Sl. No": 7,
      "Team Business": "$ 3,00,000",
      "Direct Id": "1",
      "Daily Bonus": "$ 250",
      "Achieve Date" : "",
      "Days Completed": "",
      "Gift": "Car Fund - ($ 7000)",
      "Status": ""
    },
    {
      "Sl. No": 8,
      "Team Business": "$ 10,00,000",
      "Direct Id": "1",
      "Daily Bonus": "$ 700",
      "Achieve Date" : "",
      "Days Completed": "",
      "Gift": "Car fund($ 14000) Singapore Tour (2 Person)",
      "Status": ""
    },
    {
      "Sl. No": 9,
      "Team Business": "$ 20,00,000",
      "Direct Id": "1",
      "Daily Bonus": "$ 1500",
      "Achieve Date" : "",
      "Days Completed": "",
      "Gift": "Car Fund($ 40000) + Europe Tour (2 Person)",
      "Status": ""
    },
    {
      "Sl. No": 10,
      "Team Business": "$ 50,00,000",
      "Direct Id": "1",
      "Daily Bonus": "$ 4000",
      "Achieve Date" : "",
      "Days Completed": "",
      "Gift": "$ 60000 + World Tour (2 Person)",
      "Status": ""
    },
    // Add more data objects here
  ];

// Fetch royalty income
// Fetch royalty income
const fetchRoyaltyBonus = async () => {
  setIsLoading(true);

  try {
    const rewardBonus = await getRewardStatus(memberData.memberId);
    console.log(rewardBonus)
    const currentDate = new Date();
    const updatedSampleData = sampleData.map((item, i) => {
      const achieveDate = rewardBonus.result[i] ? new Date(rewardBonus.result[i].achieveDate) : null;
      const daysCompleted = achieveDate ? Math.min(100, Math.floor((currentDate - achieveDate) / (1000 * 60 * 60 * 24))) : "-";
      const status = rewardBonus.result[i] ? rewardBonus.result[i].status : null
      
      return {
        ...item,
        "Achieve Date": achieveDate ? formatDate(achieveDate) : "-",
        "Days Completed": daysCompleted,
        "Status": status ? <h3 style={{color: "green"}}>Completed</h3> :<h3 style={{color: "grey"}}>Running</h3>,
      };
    });

    setIsLoading(false);
    setRewardBonus(updatedSampleData);
  } catch (error) {
    console.error("Error fetching transaction history:", error);
    setIsLoading(false);
  }
};



  useEffect(() => {
    fetchRoyaltyBonus();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="investment-history">
          <h1 className="main-heading">Reward Bonus status</h1>
          
          <br />
          <br />

          <div className="table-div">
            <div className="table-top">
              <div className="table-heading">
                <h2>Reward Status</h2>
              </div>
            </div>
            <div className="table-main">
              <div className="table-inner">
                <table className="table-body">
                  <tr>
                    {fields.map((field) => (
                      <th key={field.key}>{field.label}</th>
                    ))}
                  </tr>
                  {rewardBonus.map((row, index) => (
                    <tr key={index}>
                      {fields.map((field) => (
                        <td
                          key={field.key}
                          style={{
                            color:
                              row[field.key] === "Completed"
                                ? "green"
                                : row[field.key] === "Running"
                                ? "blue"
                                : row[field.key] === "Pending"
                                ? "grey"
                                : "inherit", // Use the default color if none of the conditions match
                          }}
                        >
                          {row[field.key]}
                        </td>
                      ))}
                    </tr>
                  ))}
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default RoyaltyBonus