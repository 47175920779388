import React, { useEffect, useMemo } from 'react';
import {
  useSortBy,
  useTable,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from 'react-table';
import { GlobalFilter } from './GlobalFilter';
import { AiOutlineSearch } from 'react-icons/ai';
import { Checkbox } from './CheckBox';
import './style.css';

function CustomTableCheckbox(props) {
  const memoColumns = useMemo(() => props.columns, []);
  const memoRows = useMemo(() => props.data, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    rows,
    pageCount,
    setPageSize,
    state,
    prepareRow,
    setGlobalFilter,
    selectedFlatRows,
  } = useTable(
    {
      columns: memoColumns,
      data: memoRows,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'sn',
          Header: () => null, // This removes the header
          Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} />,
        },
        ...columns,
      ]);
    }
  );

  const { globalFilter, pageIndex, pageSize } = state;

  // Call the onSelectedRows callback with selectedFlatRows when selection changes
  const handleSelectionChange = () => {
    props.onSelectedRows(selectedFlatRows);
  };


  useEffect(() => {
    handleSelectionChange();
  }, [selectedFlatRows]);
  return (
    <>
      <pre>
        <h2 className="total-selected">{`Number of selected rows: ${selectedFlatRows.length}`}</h2>
      </pre>

      <div className="table-div">
        <div className="table-top">
          <div className="table-heading">
            <h2>{props.heading}</h2>
          </div>
          <div className="table-search-input">
            <AiOutlineSearch />
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          </div>
        </div>
        <div className="table-main">
          <div className="table-inner">
            <table {...getTableProps()} responsive className="table-body">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                      >
                        {column.render('Header')}
                        <span>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? ' 🔽'
                              : ' 🔼'
                            : ''}
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="table-bottom">
            <div className="tb-left">
              <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                {'<<'}
              </button>{' '}
              <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                Previous
              </button>{' '}
              <button onClick={() => nextPage()} disabled={!canNextPage}>
                Next
              </button>{' '}
              <button
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {'>>'}
              </button>
            </div>
            <div className="tb-right">
              <label>Total Entries: {rows.length}</label>
              <select
                value={pageSize}
                onChange={(e) => setPageSize(Number(e.target.value))}
              >
                {[10, 25, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CustomTableCheckbox;
