import React, { useEffect, useState } from "react";
import Table from "../../components/table/Table";

import Loader from "../../components/loader/Loader";
import { getTransactionReport } from "../../../services/Member";

function formatDate(dateString) {
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  return new Date(dateString).toLocaleDateString("en-GB", options);
}

const fields = [
  { key: "S. No", label: "S. No" },
  { key: "Credit", label: "Credit" },
  { key: "Debit", label: "Debit" },
  { key: "Date", label: "Date" },
  { key: "Type", label: "Type" },
  { key: "Remark", label: "Remark" },
  { key: "Status", label: "Status" },
  // Add more fields as needed
];

function RoiStakedReport() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const memberData = JSON.parse(localStorage.getItem("memberData"));

  const fetchTransactionHistory = async () => {
    setIsLoading(true);
    try {
      const transactionHistory = await getTransactionReport(
        memberData.memberId,
        'ROIStaked',
        '',
        '',
        '',
        ''
      );
      
      const processedData = transactionHistory.result.transactions.map((transaction, i) => {
        const firstWord = transaction.transactionRemark.split(' ')[0]; // Extract the first word
        const capitalizedType = firstWord.charAt(0).toUpperCase() + firstWord.slice(1); // Capitalize the first word
        return {
          "S. No": i + 1,
          "Type": capitalizedType, // Use the capitalized first word as the Type
          "Credit": transaction.creditedAmount,
          "Debit": transaction.debitedAmount,
          "Wallet Name": transaction.walletName,
          "Remark": transaction.transactionRemark,
          "Status": transaction.status,
          "Date": new Date(transaction.date).toLocaleDateString(),
        };
        
      });
  
      setData(processedData);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching transaction history:', error);
      setIsLoading(false);
    }
  };
  

  useEffect(() => {
    fetchTransactionHistory();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="deposit-history">
          <h1 className="main-heading">ROI Stakked Wallet Report</h1>
          <br />
          <br />

          <Table data={data} fields={fields} heading="ROI Stakked Transaction" />
        </div>
      )}
    </>
  );
}

export default RoiStakedReport;
