import { React, useState } from "react";
import Dashboard from "./dashboard/Dashboard";
import { Routes, Route } from "react-router-dom";
import "./style.css";
import Deposit from "./deposit/Deposit";
import DepositHistory from "./deposit-history/DepositHistory";
import InvestmentHistory from "./investment-history/InvestmentHistory";
import BuyPackage from "./buy-package/BuyPackage";
import AdvbPayout from "./advb-payout/AdvbPayout";
import UsdtPayout from "./usdt-payout/UsdtPayout";
import RoiStack from "./roi-stack/RoiStack";
import RetopupHistory from "./retopup-history/RetopupHistory";
import Navbar from "../components/navbar/Navbar";
import Profile from "../components/profile/Profile";
import UsdtReport from "./usdt-payout/UsdtReport";
import AdvbReport from "./advb-payout/AdvbReport";
import RoiStakedReport from "./roi-stack/RoiStakedReport";
import TransactionReport from "./transaction-report/TransactionReport";
import Retopup from "./retopup-history/Retopup";
import ProfilePage from "./profile/ProfilePage";
import TeamBusiness from "./team-business/TeamBusiness";
import RewardBonus from "./reward-bonus/RewardBonus";
import RoyaltyBonus from "./royalty-bonus/RoyaltyBonus";
import WorkingIncome from "./working-income/WorkingIncome";
import ChangePassword from "./change-password/ChangePassword";
import AdvbStatement from "./advb-payout/AdvbStatement";
import UsdtStatement from "./usdt-payout/UsdtStatement";

function Member() {
  const [isProfile, setIsProfile] = useState(false); // State variable to track the width of mm-right
  const [isNavbar, setIsNavbar] = useState(false); // State variable to track the width of mm-right
  return (
    <>
      <div
        className={`profile-blur ${isProfile || isNavbar ? "active" : ""}`}
        onClick={() => {
          setIsProfile(false);
          setIsNavbar(false);
        }}
      ></div>
      <div className="mm-left">
        <Navbar
          setIsProfile={setIsProfile}
          isProfile={isProfile}
          isNavbar={isNavbar}
          setIsNavbar={setIsNavbar}
        />

        <div className="main-body">
          <div className="member">
            <Routes>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/deposit" element={<Deposit />} />
              <Route path="/deposit-history" element={<DepositHistory />} />
              <Route
                path="/investment-history"
                element={<InvestmentHistory />}
              />
              <Route path="/buy-package" element={<BuyPackage />} />
              <Route path="/advb-payout" element={<AdvbPayout />} />
              <Route path="/usdt-payout" element={<UsdtPayout />} />
              <Route path="/usdt-report" element={<UsdtReport />} />
              <Route path="/advb-report" element={<AdvbReport />} />
              <Route path="/transaction-report" element={<TransactionReport />} />
              <Route path="/roi-staked-report" element={<RoiStakedReport />} />
              <Route path="/roi-stack" element={<RoiStack />} />
              <Route path="/retopup" element={<Retopup />} />
              <Route path="/retopup-history" element={<RetopupHistory />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/team-business" element={<TeamBusiness />} />
              <Route path="/reward-bonus" element={<RewardBonus />} />
              <Route path="/royalty-bonus" element={<RoyaltyBonus />} />
              <Route path="/working-income" element={<WorkingIncome />} />
              <Route path="/change-password" element={<ChangePassword />} />
              <Route path="/advb-statement" element={<AdvbStatement />} />
              <Route path="/usdt-statement" element={<UsdtStatement />} />
            </Routes>
          </div>
        </div>
      </div>
      <div className={`mm-right ${isProfile ? "active" : ""}`}>
        <Profile />
      </div>
    </>
  );
}

export default Member;
