import React, { useEffect, useState } from "react";
import Table from "../../components/table/Table";
import {
  getPendingPayouts,
  proceedADVBPayout,
  returnADVBPayout,
} from "../../../services/Admin";
import Loader from "../../components/loader/Loader";
import { getProfileById } from "../../../services/Admin";
import formatDate from "../../../utils/formatDate";
import CustomTableCheckbox from "../../components/custom-table/CustomTableCheckbox";
import { toast } from "react-toastify";
import Loader2 from "../../components/loader/Loader2";

function AdvbPayout() {
  const [isLoading, setIsLoading] = useState(true);
  const [isPending, setIsPending] = useState(false);
  const [pendingPayouts, setPendingPayouts] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const columns = [
    {
      Header: "S.no",
      accessor: "sno",
    },
    {
      Header: "Member Id",
      accessor: "memberId",
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Date",
      accessor: "date",
    },
    {
      Header: "Request Amount (ADVB)",
      accessor: "requestAmount",
    },
    {
      Header: "90% Net Amount (ADVB)",
      accessor: "netAmount",
    },
    {
      Header: "10% Admin Charges (ADVB)",
      accessor: "adminCharges",
    },
    {
      Header: "Wallet Address",
      accessor: "walletAddress",
    },
    {
      Header: "Status",
      accessor: "status",
    },
  ];

  const handleApproved = async () => {
    setIsPending(true);

    const formattedSelectedRows = selectedRows.map((selectedRow) => {
      const rowData = selectedRow.original;
      const formattedRow = {};

      if (rowData.walletAddress) {
        formattedRow["_id"] = rowData._id;
        formattedRow["memberId"] = rowData.memberId;
        formattedRow["walletAddress"] = rowData.walletAddress;
        formattedRow["requestADVB"] = rowData.requestAmount;
      } else {
        // If walletAddress is missing, show a toast
        toast.error(
          `Member with ID ${rowData.memberId} doesn't have a walletAddress.`
        );
        setIsPending(false);
        return;
      }

      return formattedRow;
    });

    const allFormattedRows = [].concat(...formattedSelectedRows);

    try {
      if (allFormattedRows.length > 0) {
        const payouts = await proceedADVBPayout(allFormattedRows);
        toast.success(
          `${payouts.processedPayouts.length} transactions successful!`
        );
        window.location.reload();
      } else {
        toast.warn("No rows with wallet address!");
      }
    } catch (error) {
      console.log(error);
    }
    setIsPending(false);
  };

  const handleCancel = async () => {
    setIsPending(true);
    const formattedSelectedRows = selectedRows.map((selectedRow) => {
      const rowData = selectedRow.original;
      const formattedRow = {};

      if (rowData.walletAddress) {
        formattedRow["_id"] = rowData._id;
      } else {
        toast.error(
          `Member with ID ${rowData.memberId} doesn't have a walletAddress.`
        );
      }

      return formattedRow;
    });

    const allFormattedRows = [].concat(...formattedSelectedRows);

    try {
      if (allFormattedRows.length > 0) {
        const payouts = await returnADVBPayout(allFormattedRows);
        toast.success(
          `${payouts.processedPayouts.length} transactions Return!`
        );
        window.location.reload();
      } else {
        toast.warn("No rows with wallet address!");
      }
    } catch (error) {
      console.log(error);
    }
    setIsPending(false);
  };

  const fetchPendingPayouts = async () => {
    try {
      // Fetch pending payouts data
      const payoutData = await getPendingPayouts("ADVBWallet", "Pending");
      console.log(payoutData);

      // Create an array of promises for fetching profile data
      const profilePromises = payoutData.map(async (transaction) => {
        const profileData = await getProfileById(transaction.memberId);
        return profileData;
      });

      // Wait for all profile data requests to complete
      const profiles = await Promise.all(profilePromises);

      // Format the pending payouts data
      const formattedData = payoutData.map((transaction, i) => {
        const profileData = profiles[i];
        const currrentProfile = profileData.result.profile;

        const formattedTransaction = {
          _id: transaction._id,
          sno: i + 1,
          memberId: transaction.memberId || "-",
          name: currrentProfile.fullname || "-",
          date: transaction.date ? formatDate(transaction.date) : "-",
          requestAmount: transaction.debitedAmount
            ? transaction.debitedAmount.toFixed(2)
            : "-",
          netAmount: transaction.debitedAmount
            ? (transaction.debitedAmount * 0.9).toFixed(2)
            : "-",
          adminCharges: transaction.debitedAmount
            ? (transaction.debitedAmount * 0.1).toFixed(2)
            : "-",
          walletAddress: currrentProfile.walletAddress
            ? currrentProfile.walletAddress
            : "",
          status: transaction.status ? transaction.status : "",
        };

        return formattedTransaction;
      });

      setPendingPayouts(formattedData); // Update the state variable with formatted payout data
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching pending payouts:", error);
      setIsLoading(false);
    }
  };

  const handleSelectedRows = (selectedFlatRows) => {
    setSelectedRows(selectedFlatRows);
  };

  useEffect(() => {
    fetchPendingPayouts();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {isPending && <Loader2 />}

          <div className="usdt-payout">
            <h1 className="main-heading">ADVB Payout Requests</h1>
            <br />
            <div className="button-group">
              <button onClick={handleCancel} style={{background: "red"}}>Return withdrawal Amount</button>
              <button onClick={handleApproved} style={{background: "green"}}>Approve selected Request</button>
            </div>
            <br />
            <CustomTableCheckbox
              data={pendingPayouts}
              columns={columns}
              heading="ADVB Pending Requests"
              onSelectedRows={handleSelectedRows}
            />
          </div>
        </>
      )}
    </>
  );
}

export default AdvbPayout;
