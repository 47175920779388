import axios from "axios";

const apiURL = process.env.REACT_APP_API_URL; // Make sure you have the environment variable set correctly

const makeAuthenticatedRequest = async (url, data) => {
  const memberData = JSON.parse(localStorage.getItem("memberData"));
  const config = {
    headers: { Authorization: `Bearer ${memberData.token}` },
  };

  try {
    const response = await axios.post(url, data, config);
    return response.data;
  } catch (error) {
    if (error.response) {
      if (error.response.status === 500) {
        throw new Error(error.response.data.error);
      } else if (error.response.status === 401) {
        // Unauthorized request, clear local storage and redirect to login
        localStorage.clear();
        window.location.href = "/login";
      } else {
        throw new Error("An error occurred.");
      }
    } else {
      // Network error or server unreachable, redirect to login
      localStorage.clear();
      window.location.href = "/login";
    }
  }
};

export const makeAuthenticatedGetRequest = async (url, params) => {
  const memberData = JSON.parse(localStorage.getItem("memberData"));
  const config = {
    headers: { Authorization: `Bearer ${memberData.token}` },
    params: params, // Pass the query parameters here
  };

  try {
    const response = await axios.get(url, config);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 500) {
      throw new Error(error.response.data.error);
    } else if (error.response.statusText === "Unauthorized") {
      localStorage.clear();
      window.location.reload();
      throw new Error(error);
    } else {
      throw new Error("An error occurred.");
    }
  }
};

export const getMemberbyId = async (currentMemberId) => {
  const payLoad = {
    memberId: currentMemberId,
  };

  return await makeAuthenticatedRequest(`${apiURL}member/get-member`, payLoad);
};

export const getWalletById = async (currentMemberId) => {
  const payLoad = {
    memberId: currentMemberId,
  };

  return await makeAuthenticatedRequest(`${apiURL}member/get-wallet`, payLoad);
};

export const getProfileById = async (currentMemberId) => {
  const payLoad = {
    memberId: currentMemberId,
  };

  return await makeAuthenticatedRequest(`${apiURL}member/get-profile`, payLoad);
};

export const getRewardById = async (currentMemberId) => {
  const payLoad = {
    memberId: currentMemberId,
  };

  return await makeAuthenticatedRequest(`${apiURL}member/get-reward`, payLoad);
};

export const getIncomeStatementByType = async (memberId, incomeType) => {
  const params = {
    memberId: memberId,
    incomeType: incomeType,
  };

  return await makeAuthenticatedGetRequest(`${apiURL}member/get-income-statement`, params);
};


export const initiateDeposit = async (
  memberId,
  amount,
  mode,
  fromAddress,
  toAddress
) => {
  const payLoad = {
    memberId: memberId,
    amount: amount,
    mode: mode,
    fromAddress: fromAddress,
    toAddress: toAddress,
  };

  return await makeAuthenticatedRequest(
    `${apiURL}member/deposit-advb-init`,
    payLoad
  );
};

export const updateDepositStatus = async (
  depositHistoryId,
  memberId,
  status
) => {
  const payLoad = {
    depositHistoryId: depositHistoryId,
    memberId: memberId,
    status: status,
  };
  console.log(payLoad);

  return await makeAuthenticatedRequest(
    `${apiURL}member/deposit-advb-updt`,
    payLoad
  );
};

export const depositAdvb = async (
  memberId,
  amount,
  fromAddress,
  toAddress,
  status,
  mode,
  txn
) => {
  const payLoad = {
    memberId: memberId,
    amount: amount,
    fromAddress: fromAddress,
    toAddress: toAddress,
    status: status,
    mode: mode,
    txn: txn,
  };
  console.log(payLoad)

  return await makeAuthenticatedRequest(
    `${apiURL}member/deposit-advb`,
    payLoad
  );
};

export const getCompletedDepositHistory = async (memberId) => {
  const payLoad = { memberId: memberId };

  return await makeAuthenticatedRequest(
    `${apiURL}member/deposit-history`,
    payLoad
  );
};

export const investAdvb = async (
  memberId,
  inputMemberId,
  purchasedAmount,
  investedAmount,
  fromWallet,
  liveToken
) => {
  const payLoad = {
    memberId: memberId,
    inputMemberId: inputMemberId,
    purchasedAmount: purchasedAmount,
    investedAmount: investedAmount,
    fromWallet: fromWallet,
    liveToken: liveToken
  };

  return await makeAuthenticatedRequest(`${apiURL}member/invest-advb`, payLoad);
};

export const getAllPackages = async (memberId) => {
  const payLoad = { memberId: memberId };

  return await makeAuthenticatedRequest(
    `${apiURL}member/invest-history`,
    payLoad
  );
};

export const getActivePackages = async (memberId) => {
  const payLoad = { memberId: memberId };

  return await makeAuthenticatedRequest(
    `${apiURL}member/active-packages`,
    payLoad
  );
};

export const getAllReferrals = async (memberId) => {
  const payLoad = { memberId: memberId };

  return await makeAuthenticatedRequest(
    `${apiURL}member/get-referrals`,
    payLoad
  );
};

export const roiToAdvb = async (memberId, amount) => {
  const payLoad = { memberId: memberId, amount: amount };

  return await makeAuthenticatedRequest(`${apiURL}member/roi-to-advb`, payLoad);
};

export const getTransactionReport = async (
  memberId,
  walletName,
  status,
  transactionRemark,
  debited,
  credited
) => {
  const params = {
    memberId: memberId,
    walletName: walletName,
    status: status,
    transactionRemark: transactionRemark,
    debited: debited,
    credited: credited,
  };

  try {
    return await makeAuthenticatedGetRequest(
      `${apiURL}member/get-transaction-history`,
      params
    );
  } catch (error) {
    throw new Error(`Error fetching transaction report: ${error.message}`);
  }
};


export const getLiveRate = async () => {
  try {
    const response = await axios.get(
      "https://api.latoken.com/v2/ticker/ADVB/USDT"
    );

    if (response.data && response.data.lastPrice) {
      const liveRate = parseFloat(response.data.lastPrice);
      return liveRate;
    } else {
      throw new Error("Invalid response format");
    }
  } catch (error) {
    console.error("Error fetching live rate:", error);
    throw error;
  }
};


export const getLiveRateAPI = async () => {
  return await makeAuthenticatedGetRequest(`${apiURL}member/get-live-rate`);
};

export const getAnnoucment = async () => {
  return await makeAuthenticatedGetRequest(`${apiURL}member/get-annoucment`);
};

export const holdRoi = async (memberId, packageType, amount) => {
  const payLoad = {
    memberId: memberId,
    packageType: packageType,
    amount: amount,
  };

  return await makeAuthenticatedRequest(`${apiURL}member/hold-roi`, payLoad);
};

export const getUserHoldRoi = async (memberId) => {
  const payLoad = {
    memberId: memberId,
  };

  return await makeAuthenticatedRequest(
    `${apiURL}member/get-user-hold-roi`,
    payLoad
  );
};

export const walletActivationRequest = async (memberId, walletName) => {
  const payLoad = {
    memberId: memberId,
    walletName: walletName,
  };

  return await makeAuthenticatedRequest(
    `${apiURL}member/wallet-activation`,
    payLoad
  );
};

export const getDirectReferralsCount = async (memberId) => {
  const payLoad = {
    memberId: memberId,
  };

  return await makeAuthenticatedRequest(
    `${apiURL}member/get-direct-referrals-count`,
    payLoad
  );
};

export const getTeamBusiness = async (memberId) => {
  const payLoad = {
    memberId: memberId,
  };

  return await makeAuthenticatedRequest(
    `${apiURL}member/get-team-business`,
    payLoad
  );
};

// profileService.js

export const getAllBonuses = async (memberId) => {
  const payLoad = {
    memberId: memberId,
  };

  return await makeAuthenticatedRequest(
    `${apiURL}member/get-all-bonuses`,
    payLoad
  );
};

export const getUSDTCredits = async (memberId) => {
  const payLoad = {
    memberId: memberId,
  };

  return await makeAuthenticatedRequest(
    `${apiURL}member/get-usdt-credits-balance`,
    payLoad
  );
};

export const updateProfile = async (updatedProfileData) => {
  try {
    const payload = {
      memberId: updatedProfileData.memberId,
      fullname: updatedProfileData.fullName,
      mobile: updatedProfileData.mobile,
      email: updatedProfileData.email,
      walletAddress: updatedProfileData.walletAddress,
    };

    // Make an authenticated request to update the user profile
    const response = await makeAuthenticatedRequest(
      `${apiURL}member/update-profile`,
      payload
    );

    return response; // You can handle the response accordingly in your component
  } catch (error) {
    // Handle errors or return an error response
    console.error("Error updating profile:", error);
    throw error;
  }
};

export const initPayout = async (memberId, walletName, amount) => {
  try {
    const payload = {
      memberId: memberId,
      walletName: walletName,
      amount: amount,
    };

    // Make an authenticated request to update the user profile
    const response = await makeAuthenticatedRequest(
      `${apiURL}member/init-payout`,
      payload
    );

    return response;
  } catch (error) {
    console.error("Error initiliz transaction:", error);
    throw error;
  }
};

export const getWithdrawalStatus = async (walletName) => {
  const status = await makeAuthenticatedGetRequest(
    `${apiURL}member/get-withdrawal-status`
  );
  if (walletName === "ADVB") {
    return status.ADVB;
  }
  if (walletName === "USDT") {
    return status.USDT;
  }
};

export const generateAndSaveOTP = async (memberId) => {
  try {
    const payload = {
      memberId: memberId,
    };

    // Make an authenticated request to update the user profile
    const response = await makeAuthenticatedRequest(
      `${apiURL}member/generate-otp`,
      payload
    );

    return response;
  } catch (error) {
    console.error("Error initiliz transaction:", error);
    throw error;
  }
};

export const verifyOTP = async (memberId, enteredOTP) => {
  try {
    const payload = {
      memberId: memberId,
      enteredOTP: enteredOTP,
    };

    // Make an authenticated request to update the user profile
    const response = await makeAuthenticatedRequest(
      `${apiURL}member/verify-otp`,
      payload
    );

    return response.result;
  } catch (error) {
    console.error("Error initiliz transaction:", error);
    return false
  }
};

export const getRoyaltyStatus = async (memberId) => {
  try {
    const payload = {
      memberId: memberId,
    };

    // Make an authenticated request to update the user profile
    const response = await makeAuthenticatedRequest(
      `${apiURL}member/get-royalty-status`,
      payload
    );

    return response;
  } catch (error) {
    console.error("Error getting royalty status:", error);
    throw error;
  }
};

export const getRewardStatus = async (memberId) => {
  try {
    const payload = {
      memberId: memberId,
    };

    // Make an authenticated request to update the user profile
    const response = await makeAuthenticatedRequest(
      `${apiURL}member/get-reward-status`,
      payload
    );

    return response;
  } catch (error) {
    console.error("Error getting royalty status:", error);
    throw error;
  }
};
