import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import Loader from "../../components/loader/Loader";
import Loader2 from "../../components/loader/Loader2";
import { toast } from "react-toastify";
import { getProfileAndMember, updateProfile } from "../../../services/Admin";

function ProfileUpdate() {
  const [isLoading, setIsLoading] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [showProfileUpdate, setShowProfileUpdate] = useState(false);

  const [formData, setFormData] = useState({
    fullName: "",
    memberId: "",
    mobileNumber: "",
    email: "",
    walletAddress: "",
    blockStatus: "",
  });

  const fetchUserDetails = async (noToast) => {
    console.log(noToast)
    if (formData.memberId === "") {
      setShowProfileUpdate(false);
      toast.warn("please enter memberId!");
      return;
    }
    setIsPending(true);
    try {
      const memberId = formData.memberId.toUpperCase();
      const member = await getProfileAndMember(memberId);
      setFormData((prevData) => ({
        ...prevData,
        fullName: member.result.profile.fullname || "",
        mobileNumber: member.result.profile.mobile || "",
        email: member.result.member.email || "",
        walletAddress: member.result.profile.walletAddress || "",
        blockStatus: member.result.member.status || "",
      }));
      
    //   console.log(formData)
      setShowProfileUpdate(true);
      setIsPending(false);
      if(!noToast){
        toast.success("Valid Member!");
      }
    } catch (error) {
      //   console.error("Error fetching user details:", error);
      toast.error("Invalid Member Id");
      setShowProfileUpdate(false);
      setIsPending(false);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    // If the changed field is the select element, set value directly
    if (name === "blockStatus") {
      setFormData({
        ...formData,
        [name]: value,
      });
    } else {
      // For other fields, use the previous approach
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async () => {
    if(!formData.fullName){
        toast.warn("Please enter Name, Email, Number!!")
        return
    }
    if(!formData.mobileNumber){
        toast.warn("Please enter Number!!")
        return
    }
    if(!formData.email){
        toast.warn("Please enter Email!!")
        return
    }
    setIsPending(true);

    try {
        const memberId = formData.memberId.toUpperCase();
        setFormData((prevData) => ({
            ...prevData,
           memberId: memberId
          }));
      await updateProfile(formData)
      toast.success("Profile updated!");
      fetchUserDetails(true);
    } catch (error) {
      toast.error("Something went wrong!");
      console.error("Error updating profile:", error);
    }

    setIsPending(false);
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="deposit">
          <h1 className="main-heading">Profile Update</h1>

          {isPending && <Loader2 />}
          <div className="adpa-first">
            <div className="adpafi-top">
              <h2>Enter Member ID</h2>
            </div>
            <div className="adpafi-body">
              <div className="input-group">
                <label>Member Id</label>
                <input
                  type="text"
                  name="memberId"
                  value={formData.memberId}
                  onChange={handleInputChange}
                />
              </div>
              <div className="button-group">
                <button type="button" onClick={()=>{fetchUserDetails(false)}}>
                  Continue
                </button>
              </div>
            </div>
          </div>

          {showProfileUpdate && (
            <div className="adpa-first">
              <div className="adpafi-top">
                <h2>Profile Update info</h2>
              </div>
              <div className="adpafi-body">
                {/* Display user details here */}
                    <div className="input-group">
                      <label>Full name</label>
                      <input
                        type="text"
                        name="fullName"
                        value={formData.fullName}
                        onChange={handleInputChange}
                        maxLength="20"
                        required
                      />
                    </div>

                    <div className="input-group">
                      <label>Mobile number</label>
                      <input
                        type="text"
                        name="mobileNumber"
                        value={formData.mobileNumber}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="input-group">
                      <label>Email Id</label>
                      <input
                        type="text"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="input-group">
                      <label>Wallet Address</label>
                      <input
                        type="text"
                        name="walletAddress"
                        value={formData.walletAddress}
                        onChange={handleInputChange}
                      />
                    </div>

                    {/* <div className="input-group">
                      <label>Block Status</label>
                      <select
                        name="blockStatus"
                        value={formData.blockStatus}
                        onChange={handleInputChange}
                      >
                        <option value="Active">Unblock</option>
                        <option value="Inactive">Block</option>
                      </select>
                    </div> */}

                <div className="button-group">
                  <button type="submit" onClick={handleSubmit}>
                    Update
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default ProfileUpdate;
