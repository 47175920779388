import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Table from "../../components/table/Table";

import Loader from "../../components/loader/Loader";
import { getTransactionReport } from "../../../services/Member";
import CustomTable from "../../components/custom-table/CustomTable";
import formatDate from "../../../utils/formatDate";

const columns = [
    {
      Header: "S.no",
      accessor: "sno",
    },
    {
      Header: "Credit (ADVB)",
      accessor: "credit",
    },
    {
      Header: "Debit (ADVB)",
      accessor: "debit",
    },
    {
      Header: "Date",
      accessor: "date",
    },
    {
      Header: "Type",
      accessor: "type",
    },
    {
      Header: "Remark",
      accessor: "remark",
    },
    {
      Header: "Status",
      accessor: "status",
    },
  
  ];

function UsdtStatement() {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const memberData = JSON.parse(localStorage.getItem("memberData"));

  const fetchTransactionHistory = async () => {
    setIsLoading(true);
    try {
      const transactionHistory = await getTransactionReport(
        memberData.memberId,
        "USDTWallet",
        "",
        "",
        "",
        ""
      );


      const processedData = transactionHistory.result.transactions.map(
        (transaction, i) => {
          const typeAndRemark = transaction.transactionRemark.split(" - "); // Split by ' - ' to separate type and remark
          const type = typeAndRemark[0]; // Type is the first part
          const remark = typeAndRemark[1]; // Remark is the second part

          return {
            sno: i + 1,
            credit: transaction.creditedAmount
              ? transaction.creditedAmount.toFixed(2)
              : 0,
            debit: transaction.debitedAmount
              ? transaction.debitedAmount.toFixed(2)
              : 0,
            date: formatDate(transaction.date),
            type: type || "",
            remark: remark || "",
            status: transaction.status ? transaction.status == "Completed" ? (<p style={{color: "Green"}}>{transaction.status}</p>) : (<p>{transaction.status}</p>) : ""
            
          };
        }
      );

      setData(processedData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching transaction history:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTransactionHistory();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="deposit-history">
          <h1 className="main-heading">USDT Wallet Statement</h1>
          <br />
          <br />

          <CustomTable
            data={data}
            columns={columns}
            heading="USDT Wallet history"
          />
        </div>
      )}
    </>
  );
}

export default UsdtStatement;
