import { Outlet, Navigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

function PrivateMemberRoutes() {
  const user = localStorage.getItem("memberData");
  
  if (!user) {
    return <Navigate to="./login" />;
  }

  const memberData = JSON.parse(user);

  try {
    const decodedToken = jwtDecode(memberData.token);

    const auth = decodedToken.userType === "member";

    return auth ? <Outlet /> : <Navigate to="/login" />;
  } catch (error) {
    console.error('Token decoding error:', error);
    return <Navigate to="/login" />;
  }
}

export default PrivateMemberRoutes;
