import React from 'react'
import loader from '../../assets/images/loader.gif'
import './style.css'

function Loader2() {
  return (
    <div className='loader2'>
      <img src="https://mir-s3-cdn-cf.behance.net/project_modules/disp/35771931234507.564a1d2403b3a.gif" alt='loader' />
    </div>
  )
}

export default Loader2
