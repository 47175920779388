import {React, useState} from 'react'
import './style.css'

function Dashboard() {

  const [calcData, setCalcData] = useState({
    totalBusiness: 1000
  })
    

  return (
    <div>
      <h1 className="main-heading">Dashboard</h1>
      <br />
      <br />
      <div className='admin-dashboard'>
        <div className='ad-da-first'>
          <div className='ad-fa-fi-item'>
            <h2>Total Business</h2>
            <h1>$ {calcData['totalBusiness'] || 0}</h1>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Dashboard
