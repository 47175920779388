import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loader from "../../components/loader/Loader";
import { getLiveRateAPI, getWalletById, investAdvb } from "../../../services/Member";
import Loader2 from "../../components/loader/Loader2";
import { toast } from "react-toastify";

function Retopup() {
  const [reTopUpBalance, setReTopUpBalance] = useState(0);
  const [livePrice, setLivePrice] = useState(0);
  const [selectedPackage, setSelectedPackage] = useState("100");
  const [isPending, setIsPending] = useState(false);
  const [isLoading, setIsLoading] = useState({
      fetchwallet: true,
      fetchLiveRate: true
    });
    
  const memberData = JSON.parse(localStorage.getItem("memberData"));
  const fetchWalletDetails = async () => {
    setIsLoading((prevState) => ({ ...prevState, fetchwallet: true }));
    try {
      const walletDetails = await getWalletById(memberData.memberId);
      setReTopUpBalance(walletDetails.result.wallet.retopupWallet);
      setIsLoading((prevState) => ({ ...prevState, fetchwallet: false }));
    } catch (error) {
      console.error("Error fetching wallet details:", error);
      setIsLoading((prevState) => ({ ...prevState, fetchwallet: false }));
    }
  };

  const fetchLiveRate = async () => {
    setIsLoading((prevLoadingState) => ({
      ...prevLoadingState,
      fetchLiveRate: true,
    }));
    try {
      const rate = await getLiveRateAPI();
      setLivePrice(parseFloat(rate.liveRate));
      setIsLoading((prevLoadingState) => ({
        ...prevLoadingState,
        fetchLiveRate: false,
      }));
    } catch (error) {
      console.error("Error fetching live rate:", error);
      setIsLoading((prevLoadingState) => ({
        ...prevLoadingState,
        fetchLiveRate: false,
      }));
    }
  };

  const handlePackageChange = (e) => {
    const packageValue = e.target.value;
    setSelectedPackage(packageValue);
  };

  const handleSubmit = async () => {
    setIsPending(true);

    // Calculate the amount based on the selected package and live price
    const packageAmount = parseFloat(selectedPackage);
    const amount = packageAmount;

    if (reTopUpBalance < packageAmount) {
      toast.warn("Not enough funds!");
      setIsPending(false);
      return;
    }

    try {
      // Assuming investAdvb is an async function
      const response = await investAdvb(
        memberData.memberId,
        memberData.memberId,
        amount,
        amount / livePrice,
        "Retopup",
        livePrice
      );
      console.log(response);

      if (response.message === "Insufficient balance in the wallet") {
        toast.error("Insufficient balance in the wallet");
      } else if (response.message === "Investment successful") {
        toast.success("Investment successful!");
      } else {
        toast.error("User not found");
      }
      setIsPending(false);
    } catch (error) {
      // Show error toast
      toast.error("Investment failed. Please try again later.");
      setIsPending(false);
    }

    // Reset the form fields
        fetchWalletDetails();
  };

  useEffect(() => {
    fetchWalletDetails();
    fetchLiveRate()
  }, []);

  return (
    <>
      {isLoading.fetchwallet ? (
        <Loader />
      ) : (
        <>
        {isPending && <Loader2 />}{" "}
        <div>
          <h1 className="main-heading">
            Activate Self Id From Re-Topup Wallet
          </h1>
          <div className="deposit-first">
            <div className="defi-top">
              <div className="defito-left">
                <h2>Current Balance</h2>
                <h1>$ {reTopUpBalance ? reTopUpBalance.toFixed(2) : "0.00"}</h1>
              </div>
              <div className="defito-right">
                <Link to="../retopup-history">Report</Link>
              </div>
            </div>
            <div className="defi-second"></div>
          </div>
          <div className="adpa-first">
            <div className="adpafi-top">
              <h2>Re topup account </h2>
            </div>
            <div className="adpafi-body">

            <div className="input-group">
                      <label>Select Package</label>
                      <select
                        name="package"
                        onChange={handlePackageChange}
                        value={selectedPackage}
                      >
                        <option value="100">Package 100USD</option>
                        <option value="200">Package 200USD</option>
                        <option value="500">Package 500USD</option>
                        <option value="1000">Package 1000USD</option>
                        <option value="1100">Package 1100USD</option>
                        <option value="1200">Package 1200USD</option>
                        <option value="1300">Package 1300USD</option>
                        <option value="1400">Package 1400USD</option>
                        <option value="1500">Package 1500USD</option>
                        <option value="1600">Package 1600USD</option>
                        <option value="1700">Package 1700USD</option>
                        <option value="1800">Package 1800USD</option>
                        <option value="1900">Package 1900USD</option>
                        <option value="2000">Package 2000USD</option>
                        <option value="3000">Package 3000USD</option>
                        <option value="5000">Package 5000USD</option>
                        <option value="10000">Package 10000USD</option>
                        <option value="15000">Package 15000USD</option>
                        <option value="20000">Package 20000USD</option>
                        <option value="25000">Package 25000USD</option>
                        {/* Add other package options */}
                      </select>
                    </div>

              <div className="button-group">
                <button
                  onClick={() => {
                     handleSubmit();
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        </>
      )}
    </>
  );
}

export default Retopup;
