import React, { useEffect, useState } from "react";
import Table from "../../components/table/Table";
import Loader from "../../components/loader/Loader";
import { getTransactionReport } from "../../../services/Member";
import { useLocation } from "react-router-dom";

const fields = [
  { key: "S. No", label: "S. No" },
  { key: "Credit", label: "Credit" },
  { key: "Debit", label: "Debit" },
  { key: "Date", label: "Date" },
  { key: "Type", label: "Type" },
  { key: "Remark", label: "Remark" },
  { key: "Status", label: "Status" },
  // Add more fields as needed
];

function TransactionReport() {
  const memberData = JSON.parse(localStorage.getItem("memberData"));
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [paramsData, setParamsData] = useState({
    memberId: memberData.memberId,
    walletName: "",
    status: "",
    transactionRemark: "",
    debited: "",
    credited: "",
  });

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    // Update 'walletName' in paramsData based on the query parameter
    const walletName = queryParams.get("walletName");
    if (walletName) {
      setParamsData((prevState) => ({ ...prevState, walletName }));
    }

    // Fetch transaction history
    const fetchTransactionHistory = async () => {
      setIsLoading(true);
      try {
        const transactionHistory = await getTransactionReport(
          paramsData.memberId,
          paramsData.walletName,
          paramsData.status,
          paramsData.transactionRemark,
          paramsData.debited,
          paramsData.credited
        );

        const processedData = transactionHistory.result.transactions.map(
          (transaction, i) => {
            const firstWord = transaction.transactionRemark.split(" ")[0];
            const capitalizedType =
              firstWord.charAt(0).toUpperCase() + firstWord.slice(1);
            return {
              "S. No": i + 1,
              Type: capitalizedType,
              Credit: transaction.creditedAmount,
              Debit: transaction.debitedAmount,
              "Wallet Name": transaction.walletName,
              Remark: transaction.transactionRemark,
              Status: transaction.status,
              Date: new Date(transaction.date).toLocaleDateString(),
            };
          }
        );

        setData(processedData);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching transaction history:", error);
        setIsLoading(false);
      }
    };

    fetchTransactionHistory();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="transaction-report">
          <h1 className="main-heading">{`${paramsData.walletName} Report`}</h1>
          <br />
          <br />
          <Table
            data={data}
            fields={fields}
            heading={`${paramsData.walletName} Transaction`}
          />
        </div>
      )}
    </>
  );
}

export default TransactionReport;
