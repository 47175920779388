import { useEffect, useState } from "react";
import React from 'react'
import Dashboard from "./dashboard/Dashboard";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import Sidebar from "../components/sidebar/Sidebar";
import './style.css'
import Sidebar2 from "../components/sidebar/Sidebar2";
import Members from "./Members/Members";
import UsdtPayout from "./usdtPayout/UsdtPayout";
import AdvbPayout from "./advbPayout/AdvbPayout";
import Settings from "./settings/Settings";
import UsdtPayoutApproved from "./usdtPayout/UsdtPayoutApproved";
import UsdtPayoutFailed from "./usdtPayout/UsdtFailed";
import AdvbPayoutApproved from "./advbPayout/AdvbPayoutApproved";
import AdvbPayoutFailed from "./advbPayout/AdvbPayoutFailed";
import ProfileUpdate from "./profile-update.js/ProfileUpdate";
function Admin() {

  const [isSidebar, setIsSidebar] = useState(true);

  return (
    <div className="mm-left">
      {isSidebar && <div className="admin-bg" onClick={()=>setIsSidebar(prev => !prev)}></div>}
      <Sidebar setIsSidebar={setIsSidebar} isSidebar={isSidebar} />

      <div className="admin-main-body">
      <Sidebar2 isSidebar={isSidebar} setIsSidebar={setIsSidebar} />
        <div className="admin-left">
          <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/all-members" element={<Members />} />
            <Route path="/profile-update" element={<ProfileUpdate />} />
            <Route path="/usdt-payout" element={<UsdtPayout />} />
            <Route path="/usdt-payout-approved" element={<UsdtPayoutApproved />} />
            <Route path="/usdt-payout-failed" element={<UsdtPayoutFailed />} />
            <Route path="/advb-payout" element={<AdvbPayout />} />
            <Route path="/advb-payout-approved" element={<AdvbPayoutApproved />} />
            <Route path="/advb-payout-failed" element={<AdvbPayoutFailed />} />
            <Route path="/settings" element={<Settings />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default Admin;
