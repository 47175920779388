import React, { useEffect, useState } from "react";
import { AiOutlineLogout, AiOutlineTeam, AiOutlineUser } from "react-icons/ai";
import { HiOutlineIdentification } from "react-icons/hi";
import { MdOutlineWorkspacePremium } from "react-icons/md";
import { IoBriefcaseOutline } from "react-icons/io5";
import { IoMdWifi } from "react-icons/io";
import { IoCheckmarkDoneCircleOutline } from "react-icons/io5";
import { BsPeople } from "react-icons/bs";
import {
  getDirectReferralsCount,
  getMemberbyId,
  getProfileById,
  getRewardById,
  getWalletById,
} from "../../../services/Member";
import "./style.css";

import "./style.css";
import { Link } from "react-router-dom";

function Profile() {
  const memberData = JSON.parse(localStorage.getItem("memberData"));

  const [profileInfo, setProfileInfo] = useState({
    memberId: "",
    fullName: "",
    Royalty: "",
    boosterStatus: "",
    boosterTimeLeft: "",
    sponsorId: "",
    status: "",
    Referrals: "",
  });

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const memberDetails = await getMemberbyId(memberData.memberId);
        const profiledetails = await getProfileById(memberData.memberId);
        const walletDetails = await getWalletById(memberData.memberId);
        const rewardDetails = await getRewardById(memberData.memberId);
        const directReferrals = await getDirectReferralsCount(
          memberData.memberId
        );

        setProfileInfo((prevProfileInfo) => ({
          ...prevProfileInfo,
          memberId: memberDetails.result.member.memberId,
          sponsorId: memberDetails.result.member.sponsorId,
          fullName: profiledetails.result.profile.fullname,
          boosterStatus: rewardDetails.result.reward.booster.status,
          boosterTimeLeft: rewardDetails.result.reward.booster.activationDate,
          Royalty: rewardDetails.result.reward.royaltyStatus,
          Referrals: directReferrals ? directReferrals.count : 0,
          status:
            walletDetails.result.totalInvestment === 0 ? "Inactive" : "Active",
        }));
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchUserDetails();
  }, []);

  function getBoosterPercentage(status) {
    switch (status) {
      case 0:
        return "0";
      case 1:
        return "20%";
      case 2:
        return "40%";
      case 3:
        return "60%";
      case 4:
        return "80%";
      case 5:
        return "Achieved";
      case 6:
        return "Not achieved";
      default:
        return "";
    }
  }

  function getTimeLeft(boosterTimeLeft) {
    const activationTime = new Date(boosterTimeLeft);
    activationTime.setHours(activationTime.getHours() + 48);
    const currentTime = new Date();
    const timeDifference = activationTime - currentTime;

    const remainingHours = Math.floor(timeDifference / (60 * 60 * 1000));

    return remainingHours || 0;
  }

  const data = [
    {
      id: 0,
      icon: <HiOutlineIdentification />, 
      title: "Member Id",
      value: profileInfo.memberId ? profileInfo.memberId : "-",
    },
    {
      id: 1,
      icon: <MdOutlineWorkspacePremium />, // Use the icon component directly
      title: "Royalty",
      value: profileInfo.Royalty
        ? profileInfo.Royalty === 0
          ? "Member"
          : profileInfo.Royalty === 1
          ? "Silver star"
          : profileInfo.Royalty === 2
          ? "Gold Star"
          : profileInfo.Royalty === 3
          ? "Diamond Star"
          : profileInfo.Royalty === 4
          ? "Adverb Warrior"
          : profileInfo.Royalty === 5
          ? "Core Member"
          : profileInfo.Royalty === 6
          ? "Core Director"
          : "Unknown"
        : "Member",
    },
    {
      icon: <IoMdWifi />,
      title: "Booster Status",
      value:
        profileInfo.boosterStatus === undefined
          ? "Not achieved"
          : getBoosterPercentage(profileInfo.boosterStatus) +
            (profileInfo.boosterStatus !== 5 &&
            profileInfo.boosterStatus !== 6 &&
            profileInfo.boosterTimeLeft !== null
              ? getTimeLeft(profileInfo.boosterTimeLeft) < 0
                ? ""
                : " - " + getTimeLeft(profileInfo.boosterTimeLeft) + "hr"
              : ""),
    },

    {
      id: 3,
      icon: <IoBriefcaseOutline />, // Use the icon component directly
      title: "Sponsor",
      value: profileInfo.sponsorId ? profileInfo.sponsorId : "-",
    },
    {
      id: 4,
      icon: <IoCheckmarkDoneCircleOutline />, // Use the icon component directly
      title: "Status",
      value: profileInfo.status ? profileInfo.status : "-",
    },
    {
      id: 5,
      icon: <BsPeople />, // Use the icon component directly
      title: "Referrals",
      value: profileInfo.Referrals ? profileInfo.Referrals : 0,
    },
  ];

  return (
    <div className="profile">
      <div className="profile-top">
        <h1>{"Profile"}</h1>
        <Link to="/logout">
          <AiOutlineLogout />
        </Link>
      </div>
      <div className="profile-head">
        <img
          src="https://res.cloudinary.com/dev6cpp4u/image/upload/v1696327381/profile_tls1bg.png"
          alt="pro-img"
        />
        <h3>{profileInfo.fullName || "Member Id"}</h3>
      </div>
      <div className="pro-infos">
        {data.map((item) => (
          <div className="pro-info" key={item.id}>
            <div className="pi-left">
              {item.icon} {/* Render the icon here */}
              <h2>{item.title}</h2>
            </div>
            <div className="pi-right">
              <p>{item.value}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="quicklinks">
        <h2>Quick Links</h2>
        <div className="quick-links-items">
          <Link to="/member/team-business" title="Teams">
            <img
              src="https://res.cloudinary.com/dev6cpp4u/image/upload/v1690360434/quicklinks-1_q0bnot.png"
              alt="quick-links-1"
            />
          </Link>

          <Link to="/member/profile" title="Profile">
            <img
              src="https://res.cloudinary.com/dev6cpp4u/image/upload/v1690360434/quicklinks-2_sse9rj.png"
              alt="quick-links-1"
            />
          </Link>

          <Link to="/member/working-income" title="Statement">
            <img
              src="https://res.cloudinary.com/dev6cpp4u/image/upload/v1690360434/quicklinks-3_oncizl.png"
              alt="quick-links-1"
            />
          </Link>

          <Link to="/member/reward-bonus" title="Rewards">
            <img
              src="https://res.cloudinary.com/dev6cpp4u/image/upload/v1690360434/quicklinks-4_jkq5o1.png"
              alt="quick-links-1"
            />
          </Link>

          <Link to="./" title="Support">
            <img
              src="https://res.cloudinary.com/dev6cpp4u/image/upload/v1690360434/quicklinks-5_oqsbjp.png"
              alt="quick-links-1"
            />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Profile;
