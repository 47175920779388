import React, { useEffect, useState } from "react";

import Table from "../../components/table/Table";
import Loader from "../../components/loader/Loader";
import {
  getUserHoldRoi,
  getWalletById,
  holdRoi,
  roiToAdvb,
} from "../../../services/Member";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Loader2 from "../../components/loader/Loader2";
import CustomTable from "../../components/custom-table/CustomTable";

function formatDate(dateString) {
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  return new Date(dateString).toLocaleDateString("en-GB", options);
}

function RoiStack() {
  const [ROIStaked, setROIStaked] = useState(0);
  const [roiHoldings, setRoiHoldings] = useState([]);
  const [isLoading, setIsLoading] = useState({
    fetchwallet: true,
    fetchRoi: true,
  });
  const [isPending, setIsPending] = useState(false);
  const memberData = JSON.parse(localStorage.getItem("memberData"));
  const [selectedPackageType, setSelectedPackageType] = useState("1");
  const [enteredAmount, setEnteredAmount] = useState("");

  const columns = [
    { accessor: "sn", Header: "S.no" },
    { accessor: "type", Header: "Holding Months" },
    { accessor: "amount", Header: "Hold Amount" },
    { accessor: "releaseAmount", Header: "Release Amount" },
    { accessor: "holdingDate", Header: "Holding Date" },
    { accessor: "releaseDate", Header: "Release Date" },
    { accessor: "status", Header: "Status" },
    // Add more fields as needed
  ];

  const fetchWalletDetails = async () => {
    setIsLoading((prevState) => ({ ...prevState, fetchwallet: true }));
    try {
      const walletDetails = await getWalletById(memberData.memberId);
      setROIStaked(walletDetails.result.wallet.ROIStaked);
      setIsLoading((prevState) => ({ ...prevState, fetchwallet: false }));
    } catch (error) {
      console.error("Error fetching wallet details:", error);
      setIsLoading((prevState) => ({ ...prevState, fetchwallet: false }));
    }
  };

  const fetchRoiHoldings = async () => {
    setIsLoading((prevState) => ({ ...prevState, fetchRoi: true }));
    try {
      const roiHoldingsResponse = await getUserHoldRoi(memberData.memberId);
      const roiHoldingsData = roiHoldingsResponse.result;

      const newArr = roiHoldingsData.map((entry, index) => ({
        sn: index + 1,
        holdingDate: formatDate(entry.timeOfHolding),
        releaseDate: formatDate(
          new Date(entry.timeOfHolding).setMonth(
            new Date(entry.timeOfHolding).getMonth() + +entry.holdingMonths
          )
        ),
        status: entry.status === "Active" ? <h3 style={{color: "green"}}>Active</h3> : <h3 style={{color: "Red"}}>Mature</h3>,
        type: `${entry.holdingMonths} ${
          entry.holdingMonths > 1 ? "Months" : "Month"
        }`,
        amount: entry.holdingAmount ? entry.holdingAmount.toFixed(2) : 0,
        releaseAmount: entry.releaseAmount ? entry.releaseAmount.toFixed(2) : 0,
        Remark: entry.userId,
      }));

      setRoiHoldings(newArr);
      setIsLoading((prevState) => ({ ...prevState, fetchRoi: false }));
    } catch (error) {
      console.error("Error fetching ROI holdings:", error);
      setIsLoading((prevState) => ({ ...prevState, fetchRoi: false }));
    }
  };

  const handleHoldRoi = async () => {
    setIsPending(true);
    // Check if userId is empty
    if (!enteredAmount) {
      toast.warn("Enter Amount");
      setIsPending(false);
      return;
    }
    if (!selectedPackageType) {
      toast.warn("Enter Valid user Id");
      setIsPending(false);
      return;
    }
    if (enteredAmount > RoiStack) {
      toast.warn("Not enough Balance");
      setIsPending(false);
      return;
    }
    if (enteredAmount < 10) {
      toast.warn("Minimum 10 coins required!");
      setIsPending(false);
      return;
    }

    if (selectedPackageType == "transfer") {
      try {
        const transferToAdvb = await roiToAdvb(
          memberData.memberId,
          enteredAmount
        );
        toast.success("Successfully transferred");
        fetchWalletDetails();
      } catch (error) {}
    } else {
      try {
        const response = await holdRoi(
          memberData.memberId,
          selectedPackageType,
          enteredAmount
        );
        console.log(response);
        toast.success("ROI HOld successful!");

        // Fetch updated ROI holdings after holding ROI
        fetchRoiHoldings();
        fetchWalletDetails();
      } catch (error) {
        console.error("Error holding ROI:", error);
        toast.error("Something went wrong");
      }
    }
    setIsPending(false);
  };

  useEffect(() => {
    fetchWalletDetails();
    fetchRoiHoldings(); // Fetch ROI holdings when the component mounts
  }, []);

  return (
    <>
      {isLoading.fetchwallet || isLoading.fetchRoi ? (
        <Loader />
      ) : (
        <>
          {isPending && <Loader2 />}{" "}
          <div>
            <h1 className="main-heading">Stak and withdraw coin.</h1>
            <div className="deposit-first">
              <div className="defi-top">
                <div className="defito-left">
                  <h2>Current Balance</h2>
                  <h1>$ {ROIStaked ? ROIStaked.toFixed(2) : "0.00"}</h1>
                </div>
                <div className="defito-right">
                  <Link to="../roi-staked-report">ROI Staked report</Link>
                </div>
              </div>
              <div className="defi-second"></div>
            </div>
            <div className="adpa-first">
              <div className="adpafi-top">
                <h2>Hold and withdraw coin</h2>
              </div>
              <div className="adpafi-body">
                <div className="input-group">
                  <label>Enter Amount</label>
                  <input
                    type="number"
                    placeholder="Enter minimum 10 USD Worth Coins"
                    value={enteredAmount}
                    onChange={(e) => setEnteredAmount(e.target.value)}
                  />
                </div>

                <div className="input-group">
                  <label>Select Package</label>
                  <select
                    placeholder="Select Package"
                    onChange={(e) => setSelectedPackageType(e.target.value)}
                    value={selectedPackageType}
                  >
                    <option value="1">1 Month 0.5% (Profit + Capital)</option>
                    <option value="3">3 Month 0.6% (Profit + Capital)</option>
                    <option value="6">6 Month 0.7% (Profit + Capital)</option>
                    <option value="12">12 Month 0.8% (Profit + Capital)</option>
                    <option value="transfer">
                      Transfer To ADVB Wallet for withdrawal
                    </option>
                  </select>
                </div>

                <div className="button-group">
                  <button
                    onClick={() => {
                      handleHoldRoi();
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>

            <CustomTable
              data={roiHoldings}
              columns={columns}
              heading="Hold Roi"
            />
          </div>
        </>
      )}
    </>
  );
}

export default RoiStack;
