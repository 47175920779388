import { React, useEffect, useRef, useState } from "react";
import "./style.css";
import {
  getMemberbyId,
  getProfileById,
  updateProfile,
} from "../../../services/Member";
import Loader from "../../components/loader/Loader";
import Loader2 from "../../components/loader/Loader2";
import { toast } from "react-toastify";

function ProfilePage() {
  const memberData = JSON.parse(localStorage.getItem("memberData"));
  const [isLoading, setIsLoading] = useState(true);
  const [isPending, setIsPending] = useState(false); // Add isPending state

  const hiddenFileInput = useRef(null);
  const [formData, setFormData] = useState({
    selectedFileName: null,
    fullName: "",
    memberId: "",
    mobileNumber: "",
    email: "",
    walletAddress: "",
    image: "",
  });

  const fetchUserDetails = async () => {
    setIsLoading(true);
    try {
      const profiledetails = await getProfileById(memberData.memberId);
      const memberDetails = await getMemberbyId(memberData.memberId);

      setFormData((prevProfileInfo) => ({
        ...prevProfileInfo,
        memberId: profiledetails.result.profile.memberId,
        fullName: profiledetails.result.profile.fullname,
        mobileNumber: profiledetails.result.profile.mobile,
        email: memberDetails.result.member.email,
        walletAddress: profiledetails.result.profile.walletAddress,
      }));

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching user details:", error);
      setIsLoading(false);
    }
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (event) => {
    const fileUploaded = event.target.files[0];
    setFormData({
      ...formData,
      selectedFileName: fileUploaded ? fileUploaded.name : null,
    });
  };

  const handleConnectWallet = async () => {
    try {
      if (window.ethereum) {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const accounts = await window.ethereum.request({
          method: "eth_accounts",
        });
        if (accounts.length > 0) {
          setFormData((prevProfileInfo) => ({
            ...prevProfileInfo,
            walletAddress: accounts[0],
          }));
        }
      } else {
        console.error("MetaMask is not available.");
      }
    } catch (error) {
      console.error("Error connecting to MetaMask:", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsPending(true); // Set isPending to true when submitting the form

    try {
      const response = await updateProfile(formData);
      toast.success("Profile updated!");
      fetchUserDetails();
      // Handle the response or provide user feedback as needed
    } catch (error) {
      toast.success("Something went wrong!");
      console.error("Error updating profile:", error);
    }

    setIsPending(false); // Set isPending to false after the update request is complete

    // You can reset the form or perform any other necessary actions here
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="deposit">
          <h1 className="main-heading">Profile</h1>

          {isPending && <Loader2 />}
          {/* <div className="adpa-first">
            <div className="adpafi-top">
              <h2>Profile Photo</h2>
            </div>
            <div className="adpafi-body">
              <div className="input-group" style={{ alignItems: "baseline" }}>
                <label>Profile image</label>
                <input
                  type="file"
                  onChange={handleFileChange}
                  ref={hiddenFileInput}
                  style={{ display: "none" }} // Make the file input element invisible
                />
                <div className="btn-inp">
                  <button className="button-upload" onClick={handleClick}>
                    select image
                  </button>
                  <p>
                    {formData.selectedFileName
                      ? formData.selectedFileName
                      : "No file choosen"}
                  </p>
                </div>
              </div>

              <div className="button-group">
                <button type="submit">Upload</button>
              </div>
            </div>
          </div> */}

          <div className="adpa-first">
            <div className="adpafi-top">
              <h2>Profile info</h2>
            </div>
            <div className="adpafi-body">
              <div className="input-group">
                <label>Member Id</label>
                <input
                  type="text"
                  name="memberId"
                  value={formData.memberId}
                  onChange={handleInputChange}
                  disabled
                />
              </div>

              <div className="input-group">
                <label>Full name</label>
                <input
                  type="text"
                  name="fullName"
                  value={formData.fullName}
                  onChange={handleInputChange}
                  maxLength="20"
                  required
                />
              </div>

              <div className="input-group">
                <label>Mobile number</label>
                <input
                  type="text"
                  name="mobileNumber"
                  value={formData.mobileNumber}
                  onChange={handleInputChange}
                  disabled
                />
              </div>

              <div className="input-group">
                <label>Email Id</label>
                <input
                  type="text"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  disabled
                />
              </div>

              <div className="input-group">
                <label>Wallet address</label>
                <input
                  type="text"
                  name="walletAddress"
                  value={formData.walletAddress}
                  onChange={handleInputChange}
                  placeholder="connect wallet or paste your wallet address here"
                />
              </div>

              <div className="button-group">
                <button type="submit" onClick={handleSubmit}>
                  Update
                </button>
                <button onClick={handleConnectWallet}>Connect Wallet</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ProfilePage;
