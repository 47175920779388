import React, { useEffect, useState } from "react";
import Web3 from "web3";
import "./syle.css";
import { Link } from "react-router-dom";
import { depositAdvb, getLiveRate, getWalletById } from "../../../services/Member";
import Loader from "../../components/loader/Loader";
import { toast } from "react-toastify";
import Loader2 from "../../components/loader/Loader2";
import abi from "./api";

function Deposit() {
  const [currentBalance, setCurrentBalance] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isPending, setIsPending] = useState(false);
  const [liveRate, setLiveRate] = useState();
  const [amount, setAmount] = useState(""); // State for the input value
  const [walletConnected, setWalletConnected] = useState(false);
  const [walletAddress, setWalletAddress] = useState("");

  const memberData = JSON.parse(localStorage.getItem("memberData"));

  const handleDepositSubmit = async () => {
    try {
      const web3 = new Web3(window.ethereum);
      if(amount < 10){
        toast.warn("Enter atleast 10 USDT!")
        return
      }
      if (!web3 || !walletConnected) {
        toast.warn("Connect to Metamask or DAPP!");
        return;
      }
      setIsPending(true);
      const contractAddress = "0xdE846b7805665CD2930cc723CaCCcbB9aC099dB2";
      const contract = new web3.eth.Contract(abi, contractAddress);
      const withdrwalAmount = Number(amount) / Number(liveRate);
      const amountInWei = web3.utils.toWei(withdrwalAmount, "ether"); 



      const gasPrice = await web3.eth.getGasPrice();

      const txObject = {
        from: walletAddress,
        to: contract.options.address,
        gasPrice: gasPrice,
        gas: 200000,
        data: contract.methods
          .transfer("0x731eedc0114C3Aa3e4E32c9ABA43DF2b9dc8e864", amountInWei)
          .encodeABI(),
      };

      const txReceipt = await web3.eth.sendTransaction(txObject);

      const depositData = await depositAdvb(
        memberData.memberId, 
        amount,  //amount
        walletAddress,   //Wallet Address
        "0x4c9Cbcb592B03f6765e3CC7B15abC9877f45a5AE", //to
        "Completed",  //status
        "Ethereum", //mode
        txReceipt.transactionHash, //hash
      )

      toast.success("Deposit successfully!");
      setIsPending(false);
      fetchWalletDetails()
    } catch (error) {
      if (error.message.includes("Insufficient balance")) {
        toast.error("Insufficient balance. Please deposit enough funds.");
      } else {
        console.error("Error submitting deposit transaction:", error.message);
        toast.error("Failed to deposit!, Insufficient balance or Gas!");
      }
      setIsPending(false);
      // Handle the error (e.g., show a message to the user)
    }
  };

  const connectWallet = async () => {
    try {
      if (window.ethereum) {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const accounts = await window.ethereum.request({
          method: "eth_accounts",
        });
        if (accounts.length > 0) {
          setWalletConnected(true);
          setWalletAddress(accounts[0]);
        }
        toast.success("Connected!")
      } else {
        toast.warn("Counld not connect! Open in trustwallet or Dapp!")
        console.error("MetaMask is not available.");
      }
    } catch (error) {
      console.error("Error connecting to MetaMask:", error);
    }
  };
  

  const handleAmountChange = (event) => {
    setAmount(event.target.value);
  };

  // fetch wallet details
  const fetchWalletDetails = async () => {
    try {
      const walletDetails = await getWalletById(memberData.memberId);
      const liveRate = await getLiveRate();
      setLiveRate(liveRate);
      setCurrentBalance(walletDetails.result.wallet.ADVBDeposit);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching user details:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchWalletDetails();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {isPending && <Loader2 />}{" "}
          <div className="deposit">
            <h1 className="main-heading">Fund Deposit</h1>
            <div className="deposit-first">
              <div className="defi-top">
                <div className="defito-left">
                  <h2>
                    {!walletConnected
                      ? "Wallet not connected"
                      : "Wallet Address"}{" "}
                  </h2>
                  <h2>
                    {walletAddress && walletAddress.substring(0, 8) + "..."}
                  </h2>
                </div>
                <div className="defito-right">
                  <div className="button-group" style={{ margin: "0px 10px" }}>
                    <button type="submit" onClick={connectWallet}>
                      {walletConnected ? "Connected" : "Connect"}
                    </button>
                  </div>
                </div>
              </div>
              <div className="defi-second"></div>
            </div>
            <div className="deposit-first">
              <div className="defi-top">
                <div className="defito-left">
                  <h2>Deposit Balance</h2>
                  <h1>
                    ${" "}
                    {currentBalance
                      ? currentBalance.toFixed(2)
                      : (0.0).toFixed(2)}
                  </h1>
                </div>
                <div className="defito-right">
                  <Link to="../deposit-history">Deposit Wallet History</Link>
                </div>
              </div>
              <div className="defi-second"></div>
            </div>

            <div className="adpa-first">
              <div className="adpafi-top">
                <h2>Fund Deposit</h2>
              </div>
              <div className="adpafi-body">
                <div className="input-group">
                  <label>Enter Amount</label>
                  <input
                    type="number"
                    placeholder="Enter Amount (Minimum 10 USDT)"
                    name="depositAmount "
                    value={amount}
                    onChange={handleAmountChange}
                  />
                </div>
                <div className="input-group">
                  <label>Amount in ADVB</label>
                  <input
                    type="text"
                    placeholder=""
                    name="walletAddress"
                    value={amount / liveRate.toFixed(4)}
                    disabled
                  />
                </div>
                <div className="input-group">
                  <label>Live Rate</label>
                  <input
                    type="text"
                    name="otp"
                    value={liveRate || 0}
                    disabled
                  />
                </div>
                <div className="dese-body">
                  <h2>DISCLAIMER:</h2>
                  <span>
                    Please take note that due to HUGE VOLATILITY in conversion
                    rate, there may be a difference in the amount sent and the
                    amount recieved depending on the time it takes for the
                    BLOCKCHAIN to recieve minimum 3 confirmations.
                  </span>
                </div>

                <br />
                <div className="button-group">
                  <button
                    onClick={() => handleDepositSubmit()}
                    style={{
                      alignItems: "center",
                      display: "flex",
                      gap: "10px",
                      justifyContent: "center",
                    }}
                  >
                    Deposit{" "}
                    <img
                      src="https://res.cloudinary.com/dev6cpp4u/image/upload/v1696427124/logo192_1_vj6czj.png"
                      alt="ADVB"
                      style={{ width: "30px" }}
                    />
                  </button>{" "}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Deposit;
