import logoMain from "../../assets/images/main-logo.png";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./style.css";
import Menuitems from "./Menuitem";
import {
  AiOutlineMenuFold,
  AiOutlineMenuUnfold,
  AiOutlineRight,
  AiOutlineUser,
} from "react-icons/ai";

function Navbar({ setIsProfile, isProfile, isNavbar, setIsNavbar }) {
  const location = useLocation();
  const [submenus, setSubmenus] = useState(
    Menuitems.filter((item) => item.submenus).map((item) => ({
      id: item.id,
      isOpen: false,
    }))
  );

  const toggleisProfile = () => {
    setIsProfile((prevIsProfile) => !prevIsProfile);
  };

  const toggleisNavbar = () => {
    setIsNavbar((prevIsNavbar) => !prevIsNavbar);
  };

  const toggleSubmenu = (submenuId) => {
    setSubmenus((prevSubmenus) =>
      prevSubmenus.map((submenu) => ({
        ...submenu,
        isOpen: submenu.id === submenuId ? !submenu.isOpen : false,
      }))
    );
  };

  const handleCloseSubmenus = () => {
    setSubmenus(submenus.map((submenu) => ({ ...submenu, isOpen: false })));
  };

  const handleKeyDown = (event, submenuId) => {
    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      event.preventDefault();
      toggleSubmenu(submenuId);
    }
  };

  useEffect(() => {
    // Log whenever the route changes
    handleCloseSubmenus();
    setIsProfile(false);
    setIsNavbar(false);
  }, [location]);
  return (
    <div className="navbar">
      <div className="navbar-left">
        <Link to="./dashboard">

        <img src={logoMain} alt="logo-main" />
        </Link>
        <div className={`navbar-left-links ${isNavbar ? "active" : ""}`}>
          <ul className="nav-links">
            {Menuitems.map((item) => (
              <li
                className={`nav-link ${
                  submenus.find((submenu) => submenu.id === item.id)?.isOpen
                    ? "active"
                    : ""
                }`}
                key={item.id}
              >
                {item.submenus ? (
                  <React.Fragment>
                    <div
                      tabIndex="0"
                      onKeyDown={(event) => handleKeyDown(event, item.id)}
                      onClick={() => toggleSubmenu(item.id)}
                      aria-expanded={
                        submenus.find((submenu) => submenu.id === item.id)
                          ?.isOpen || false
                      }
                      aria-haspopup="true"
                    >
                      {item.label}
                      <span
                        className={`arrow ${
                          submenus.find((submenu) => submenu.id === item.id)
                            ?.isOpen
                            ? "open"
                            : ""
                        }`}
                      />
                    </div>
                    {submenus.find((submenu) => submenu.id === item.id)
                      ?.isOpen && (
                      <ul className="nav-sub-links">
                        {item.submenus.map((submenu) => (
                          <li className="nav-sub-link" key={submenu.id}>
                            <Link to={submenu.link} alt="img-alt">
                              <img src={submenu.img} />
                              <div className="sub-menu-link-right">
                                <h2>{submenu.label}</h2>
                                <p>{submenu.desc}</p>
                              </div>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </React.Fragment>
                ) : (
                  <Link to={item.link}>{item.label}</Link>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="navbar-right">
        {!isNavbar ? (
          <AiOutlineMenuUnfold
            className="menu-toggle"
            onClick={() => {
              toggleisNavbar();
              setIsProfile(false);
            }}
          />
        ) : (
          <AiOutlineMenuFold
            className="menu-toggle"
            onClick={() => {
              toggleisNavbar();
            }}
          />
        )}
        {!isProfile ? (
          <AiOutlineUser
            onClick={() => {
              toggleisProfile();
              setIsNavbar(false);
            }}
          />
        ) : (
          <AiOutlineRight onClick={toggleisProfile} />
        )}
      </div>
    </div>
  );
}

export default Navbar;
