import { React, useEffect, useState } from "react";
import Loader from "../../components/loader/Loader";
import Loader2 from "../../components/loader/Loader2";
import { toast } from "react-toastify";
import { getAssets, updateAssets } from "../../../services/Admin";

function Settings() {
  const [isLoading, setIsLoading] = useState(true);
  const [isPending, setIsPending] = useState(false); 

  const [formData, setFormData] = useState({
    tokenRate: "",
    PayoutWalletKey: "",
    DepositWallet: "",
    ADVBWithdrawalStatus: "Off",
    USDTWithdrawalStatus: "Off", 
    announcement: "",
  });

  const fetchUserDetails = async () => {
    setIsLoading(true);
    try {
      const assets = await getAssets();
      const firstAsset = assets.assets[0]; 
      
      setFormData({
        tokenRate: firstAsset.tokenRate || "",
        PayoutWalletKey: firstAsset.PayoutWalletKey || "",
        DepositWallet: firstAsset.DepositWallet || "",
        ADVBWithdrawalStatus: firstAsset.ADVBWithdrawalStatus ? "On" : "Off",
        USDTWithdrawalStatus: firstAsset.USDTWithdrawalStatus ? "On" : "Off",
        announcement: firstAsset.announcement || "",
      });
  
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching user details:", error);
      setIsLoading(false);
    }
  };
  
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setIsPending(true);
  
    try {
      // Map "On" to true and "Off" to false for ADVB and USDT fields
      const updatedFormData = {
        ...formData,
        ADVBWithdrawalStatus: formData.ADVBWithdrawalStatus == "On",
        USDTWithdrawalStatus: formData.USDTWithdrawalStatus == "On",
      };
  
      // Send the updatedFormData in the request
      const response = await updateAssets(updatedFormData);
  
      fetchUserDetails();
  
      toast.success("Settings updated successfully");
  
      setIsPending(false);
    } catch (error) {
      console.error("Error updating settings:", error);
      toast.error("Failed to update settings");
      setIsPending(false);
    }
  };
  

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="deposit">
          <h1 className="main-heading">Settings</h1>

          {isPending && <Loader2 />}

          <div className="adpa-first">
            <div className="adpafi-top">
              <h2>Master settings</h2>
            </div>
            <div className="adpafi-body">
              <form onSubmit={handleFormSubmit}>
                <div className="input-group">
                  <label>Token Rate</label>
                  <input
                    type="text"
                    name="tokenRate"
                    value={formData.tokenRate}
                    onChange={handleInputChange}
                    required
                  />
                </div>  

                <div className="input-group">
                  <label>Payout Wallet Key</label>
                  <input
                    type="text"
                    name="PayoutWalletKey"
                    value={formData.PayoutWalletKey}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="input-group">
                  <label>Deposit Wallet</label>
                  <input
                    type="text"
                    name="DepositWallet"
                    value={formData.DepositWallet}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="input-group">
                  <label>ADVB Withdrawal Status</label>
                  <select
                    name="ADVBWithdrawalStatus"
                    value={formData.ADVBWithdrawalStatus}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="On">On</option>
                    <option value="Off">Off</option>
                  </select>
                </div>

                <div className="input-group">
                  <label>USDT Withdrawal Status</label>
                  <select
                    name="USDTWithdrawalStatus"
                    value={formData.USDTWithdrawalStatus}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="On">On</option>
                    <option value="Off">Off</option>
                  </select>
                </div>

                <div className="input-group">
                  <label>Announcement</label>
                  <input
                    name="announcement"
                    value={formData.announcement}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="button-group">
                  <button type="submit">Update</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Settings;
