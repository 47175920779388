import "./App.css";
import { BrowserRouter, Routes, Route, Router, Navigate } from "react-router-dom";
import Member from "./components/Member/Member";
import Admin from "./components/admin/Admin";
import React from "react";
import Login from "./components/public/login/Login";
import Register from "./components/public/register/Register";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";
import PrivateAdminRoutes from "./components/public/PrivateAdminRoutes";
import PrivateMemberRoutes from "./components/public/PrivateMemberRoutes";
import LogOut from "./components/components/logout/LogOut";
import ForgetPassword from "./components/public/forgot-password/ForgotPassword";



function App() {
  return (
    <>
      <BrowserRouter>
        <div className="member-main">
          <Routes>
            <Route path="/login" element={<Login />}/>
            <Route path="/logout" element={<LogOut />}/>
            <Route path="/register" element={<Register />}/>
            <Route path="/forgot-password" element={<ForgetPassword />}/>
            <Route element={<PrivateAdminRoutes />}>
                <Route element={<Admin/>} path="/admin/*"/>
            </Route>
            <Route element={<PrivateMemberRoutes />}>
                <Route element={<Member/>} path="/member/*"/>
            </Route>
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </div>
      </BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default App;
