import React from 'react'

function ChangePassword() {
  return (
    <div>
      Change Password
    </div>
  )
}

export default ChangePassword
