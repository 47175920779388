import React, { useEffect } from 'react';

function LogOut() {
  useEffect(() => {
    // Clear the localStorage
    localStorage.removeItem('memberData');

    // Get the root domain and redirect
    const rootDomain = window.location.origin;
    window.location.href = rootDomain;
  }, []); 

  return (
    <div>
    </div>
  );
}

export default LogOut;
