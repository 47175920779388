import React, { useState, useEffect } from "react";
import Loader from "../../components/loader/Loader";
import { getRoyaltyStatus } from "../../../services/Member";

function RoyaltyBonus() {
  const [isLoading, setIsLoading] = useState(true);
  const [royaltyBonus, setRoyaltyBonus] = useState([]);
  const [data, setData] = useState({
    directReferralCount: 0,
    royaltyStatus: 0,
    teamBusiness: 0,
    totalInvestment: 0,
  });
  const memberData = JSON.parse(localStorage.getItem("memberData"));

  const fields = [
    { key: "Sl. No", label: "SL. No" },
    { key: "Direct Id", label: "Direct Id" },
    { key: "Team Business", label: "Team Business [40-40-20]" },
    { key: "Id Upgrade", label: "Id Upgrade" },
    { key: "Rank", label: "Rank" },
    { key: "CTO", label: "C.T.0" },
    { key: "Status", label: "Status" },
    // Add more fields as needed
  ];

  // Sample data
  const sampleData = [
    {
      "Sl. No": 1,
      "Direct Id": "5*5=25",
      "Team Business": "-",
      "Id Upgrade": "-",
      Rank: "Silver Star",
      CTO: "2%",
      Status:
        data.royaltyStatus >= 1
          ? "Completed"
          : data.royaltyStatus === 0
          ? "Running"
          : "Pending",
    },
    {
      "Sl. No": 2,
      "Direct Id": "1",
      "Team Business": "40,000",
      "Id Upgrade": "100 USDT",
      Rank: "Gold Star",
      CTO: "1%",
      Status:
        data.royaltyStatus >= 2
          ? "Completed"
          : data.royaltyStatus === 1
          ? "Running"
          : "Pending",
    },
    {
      "Sl. No": 3,
      "Direct Id": "1",
      "Team Business": "1,00,000",
      "Id Upgrade": "200 USDT",
      Rank: "Diamond Star",
      CTO: "0.50%",
      Status:
        data.royaltyStatus >= 3
          ? "Completed"
          : data.royaltyStatus === 2
          ? "Running"
          : "Pending",
    },
    {
      "Sl. No": 4,
      "Direct Id": "1",
      "Team Business": "3,00,000",
      "Id Upgrade": "500 USDT",
      Rank: "Adverb Warrior",
      CTO: "0.50%",
      Status:
        data.royaltyStatus >= 4
          ? "Completed"
          : data.royaltyStatus === 3
          ? "Running"
          : "Pending",
    },
    {
      "Sl. No": 5,
      "Direct Id": "1",
      "Team Business": "10,00,000",
      "Id Upgrade": "1000 USDT",
      Rank: "Adverb Core Member",
      CTO: "0.50%",
      Status:
        data.royaltyStatus >= 5
          ? "Completed"
          : data.royaltyStatus === 4
          ? "Running"
          : "Pending",
    },
    {
      "Sl. No": 6,
      "Direct Id": "1",
      "Team Business": "20,00,000",
      "Id Upgrade": "2000 USDT",
      Rank: "Adverb Core Director",
      CTO: "0.50%",
      Status:
        data.royaltyStatus >= 6
          ? "Completed"
          : data.royaltyStatus === 5
          ? "Running"
          : "Pending",
    },
    // Add more data objects here
  ];

  // Fetch royalty income
  const fetchRoyaltyBonus = async () => {
    setIsLoading(true);

    try {
      const royaltyBonus = await getRoyaltyStatus(memberData.memberId);
      setData(royaltyBonus);
      setIsLoading(false);
      setRoyaltyBonus(sampleData); // Set sample data for demonstration
    } catch (error) {
      console.error("Error fetching transaction history:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchRoyaltyBonus();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="investment-history">
          <h1 className="main-heading">Royalty Achieved</h1>
          <div className="dashboard-zero">
            <div className="dz-item">
              <h2>Royalty Status </h2>
              <h1>
                {
                  data.royaltyStatus === 0
                    ? "Member"
                    : data.royaltyStatus === 1
                    ? "Silver Star"
                    : data.royaltyStatus === 2
                    ? "Gold Star"
                    : data.royaltyStatus === 3
                    ? "Diamond Star"
                    : data.royaltyStatus === 4
                    ? "Adverb Warrior"
                    : data.royaltyStatus === 5
                    ? "Adverb Core Warrior"
                    : data.royaltyStatus === 6
                    ? "Adverb Core Director"
                    : "Unknown" // Default case if none of the conditions match
                }
              </h1>
            </div>
            <div className="dz-item">
              <h2>Total Investment</h2>
              <h1>$ {data.totalInvestment}</h1>
            </div>

            <div className="dz-item">
              <h2>Team Business</h2>
              <h1>$ {data.teamBusiness}</h1>
            </div>
            <div className="dz-item">
              <h2>Direct Referrals</h2>
              <h1>{data.directReferralCount}</h1>
            </div>
          </div>
          <br />

          <div className="table-div">
            <div className="table-top">
              <div className="table-heading">
                <h2>Royalty Status</h2>
              </div>
            </div>
            <div className="table-main">
              <div className="table-inner">
                <table className="table-body">
                  <tr>
                    {fields.map((field) => (
                      <th key={field.key}>{field.label}</th>
                    ))}
                  </tr>
                  {royaltyBonus.map((row, index) => (
                    <tr key={index}>
                      {fields.map((field) => (
                        <td
                          key={field.key}
                          style={{
                            color:
                              row[field.key] === "Completed"
                                ? "green"
                                : row[field.key] === "Running"
                                ? "blue"
                                : row[field.key] === "Pending"
                                ? "grey"
                                : "inherit", // Use the default color if none of the conditions match
                          }}
                        >
                          {row[field.key]}
                        </td>
                      ))}
                    </tr>
                  ))}
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default RoyaltyBonus;