import React, { useState } from "react";
import logo from "../../assets/images/main-logo.png";
import { Link, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { forgotPassword, generateAndSaveOTP } from "../../../services/Auth";
import Loader2 from "../../components/loader/Loader2";

function ForgetPassword() {
  const Navigate = useNavigate();
  const [otpSent, setOtpSent] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [isOtpResendEnabled, setIsOtpResendEnabled] = useState(true);
  const [formdata, setFormData] = useState({
    memberId: "",
    newPassword: "",
    otp: "",
  });

  // Function to handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formdata,
      [name]: value,
    });
  };

  const enableOtpResend = () => {
    setIsOtpResendEnabled(true);
  };

  const sendOtp = async () => {
    if(formdata.memberId == ""){
      toast.warn("Member id required!")
      return
    }
    setIsPending(true);
    try {
      if (isOtpResendEnabled) {
        const email = await generateAndSaveOTP(formdata.memberId);
        toast.success(`OTP sent to ${email}`);
        setOtpSent(true);
      } else {
        toast.warn("You can resend OTP only after 2mins!");
        setIsPending(false);
        return;
      }
      setIsPending(false);

      // Disable OTP resending for 1 minute
      setIsOtpResendEnabled(false);
      setTimeout(enableOtpResend, 60000);
    } catch (error) {
      toast.error(error.message);
      setIsPending(false);
    }
  };

  const handleSubmit = async () => {
    setIsPending(true);
    try {
      if (!formdata.otp) {
        toast.warn("Enter OTP");
        setIsPending(false);
        return;
      }

      if (!formdata.newPassword) {
        toast.warn("Enter new password");
        setIsPending(false);
        return;
      }

      const response = await forgotPassword(
        formdata.memberId,
        formdata.otp,
        formdata.newPassword
      );
      // console.log(response);

      if (response === "Password changed successfully") {
        toast.success("Password changed successfully");
        Navigate("/login");
      } else {
        toast.error(response);
      }
      setIsPending(false);
    } catch (error) {
      toast.error(error.message);
      setIsPending(false);
    }
  };

  return (
    <>
      {isPending && <Loader2 />}

      <div className="login">
        <div className="login-first">
          <img className="login-logo" alt="logo-i" src={logo} />
        </div>
        <div className="login-main">
          <div className="loma-first">
            <h2>Forget Password</h2>
            <Link to="/login">Login!</Link>
          </div>
          <div className="loma-second">
            <div className="input-group-2">
              <label>Member Id</label>
              <input
                type="text"
                placeholder="AV12345"
                required
                name="memberId"
                value={formdata.memberId}
                onChange={handleInputChange}
              />
            </div>

            {otpSent && (
              <>
                <div className="input-group-2">
                  <label>Enter OTP</label>
                  <input
                    type="text"
                    placeholder="Enter OTP"
                    required
                    name="otp"
                    value={formdata.otp}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="input-group-2">
                  <label>New Password</label>
                  <input
                    type="password"
                    placeholder="Enter Password"
                    autoComplete="off"
                    required
                    name="newPassword"
                    value={formdata.newPassword}
                    onChange={handleInputChange}
                  />
                </div>
              </>
            )}
          </div>
          <div className="loma-fourth">
            <button onClick={sendOtp}>Send OTP {otpSent ? "Again" : ""}</button>
          </div>
          {otpSent && (
            <div className="loma-fourth">
              <button onClick={handleSubmit}>Set Password</button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ForgetPassword;
