import logoMain from "../../assets/images/main-logo.png";
import React from "react";
import './style.css'
import {
  AiOutlineLogout,
  AiOutlineMenuFold,
  AiOutlineMenuUnfold,
  AiOutlineUser,
} from "react-icons/ai";
import { Link } from "react-router-dom";


function Sidebar({ setIsSidebar, isSidebar }) {
  const handleSidebarToggle = () => {
    setIsSidebar(!isSidebar);
  };
  
  return (
    <div className="navbar">
      <div className="navbar-left">
        <img src={logoMain} alt="logo-main" />
        
      </div>
      <div className="navbar-right">
        <div onClick={handleSidebarToggle}>
          {isSidebar ? <AiOutlineMenuFold /> : <AiOutlineMenuUnfold />}
        </div>
        <Link to="/logout">
        <AiOutlineLogout />
        </Link>
      </div>
    </div>
  );
}

export default Sidebar
